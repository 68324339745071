import React from 'react';
import { RowInfo } from '../Common/RowInfo';
import { StyledCard } from '../style';
import { useSupplySourceSummaryResolver } from './useSupplySourceSummaryResolver';

export const SummaryCard = ({ isLoading, data, type }) => {
  const values = useSupplySourceSummaryResolver(type, data);
  return (
    <StyledCard title="Summary" loading={isLoading}>
      <RowInfo title="Timestamp" data={values.timestamp} />
      <RowInfo title="Supply Source" data={values.type} />
      <RowInfo title="Publisher" data={values.appName} />
      <RowInfo title="Channel" data={values.channel} />
      <RowInfo title="Device Family" data={values.deviceFamily} />
      <RowInfo title="Brand" data={values.brand} />
      <RowInfo title="Form-Factor" data={values.formFactor} />
      <RowInfo title="Device Category" data={values.deviceCategory} />
      <RowInfo title="VDM" data={values.vdm} />
      <RowInfo title="Request Type" data={values.requestType} />
    </StyledCard>
  );
};
