import React, { useState } from 'react';
import { connect } from 'dva';
import { Spin } from 'antd';
import { routerRedux } from 'dva/router';

import { Tab, TabPanel, TabNavigation } from '@hulu/react-style-components';

import CreateAdOverrideRule from './CreateAdOverrideRule';
import ViewExistingOverrideRules from './ViewExistingOverrideRules';
import GridLayout from '../Templates/GridLayout';
import { AD_INVESTIGATOR, OVERRIDES, USER_PROFILE } from '../../constants';
import { ADINVESTIGATOR_CREATE_RULES, ADINVESTIGATOR_VIEW_RULES, PERMISSION_PLACEHOLDER } from '../../testUtils';

const LEFT_HAND_SPAN = 5;
const RIGHT_HAND_SPAN = 18;
// TODO undo comment in https://jira.disneystreaming.com/browse/VIOLET-3082
// const TABLE_SCROLL_Y = { y: '42vh' };

const AdInvestigator = ({
  loading,
  // fetchAdsLoading,
  dispatch,
  ads = [],
  pathParams = {},
  searchParams = {},
  permissions = PERMISSION_PLACEHOLDER,
}) => {
  // Get Preselected Tab
  const [tab, setTab] = useState(pathParams?.tab ?? OVERRIDES);
  const [viewOverrideBy, setViewOverrideBy] = useState(searchParams?.viewType ?? USER_PROFILE);

  const handleTabClick = (_, tab) => {
    setTab(tab);
    setViewOverrideBy(USER_PROFILE);
    dispatch(routerRedux.push('/' + AD_INVESTIGATOR + '/' + tab));
  };

  return (
    <Spin spinning={loading}>
      <GridLayout
        leftHandSpan={LEFT_HAND_SPAN}
        rightHandSpan={RIGHT_HAND_SPAN}
        leftHandChildren={
          <div data-testid={ADINVESTIGATOR_CREATE_RULES}>
            {/* Create Override Rules form */}
            <CreateAdOverrideRule setTab={setTab} setViewOverrideBy={setViewOverrideBy} />
          </div>
        }
        rightHandChildren={
          <>
            <TabNavigation activeValue={tab} onTabClick={handleTabClick}>
              <Tab value={OVERRIDES} data-testid={ADINVESTIGATOR_VIEW_RULES}>
                {OVERRIDES}
              </Tab>
              {/*!* TODO undo comment in https://jira.disneystreaming.com/browse/VIOLET-3082 *!/*/}
              {/*/!* View Ads Table *!/*/}
              {/*<Tab disabled={permissions.cannot(ACCESS, VIEW_ADS)} value={ADS} data-testid={ADINVESTIGATOR_VIEW_ADS}>*/}
              {/*  {ADS}*/}
              {/*</Tab>*/}
            </TabNavigation>
            <br />

            {/*!* TODO undo comment in https://jira.disneystreaming.com/browse/VIOLET-3082 *!/*/}
            {/* View Ads Table */}
            {/*<TabPanel value={ADS} activeValue={tab}>*/}
            {/*  <TabsWrapper>*/}
            {/*    <Spin spinning={fetchAdsLoading}>*/}
            {/*      <FuzzySearchTable*/}
            {/*        columnsRender={adsColumns}*/}
            {/*        data={ads}*/}
            {/*        refresh={() => dispatch({ type: 'adCampaignProvider/pageInit' })}*/}
            {/*        dataMapper={mapAdsData}*/}
            {/*        route={'/' + AD_INVESTIGATOR + '/' + ADS}*/}
            {/*        preselected={pathParams?.adId ?? null}*/}
            {/*        scroll={TABLE_SCROLL_Y}*/}
            {/*      />*/}
            {/*    </Spin>*/}
            {/*  </TabsWrapper>*/}
            {/*</TabPanel>*/}

            {/* View Override Rules Table */}
            <TabPanel value={OVERRIDES} activeValue={tab}>
              <ViewExistingOverrideRules overrideBy={viewOverrideBy} setOverrideBy={setViewOverrideBy} />
            </TabPanel>
          </>
        }
      />
    </Spin>
  );
};

function mapStateToProps({ app, loading }) {
  return {
    pathParams: app.pathParams,
    searchParams: app.searchParams,
    permissions: app.permissions,
    // TODO undo comments in https://jira.disneystreaming.com/browse/VIOLET-3082
    // ads: adCampaignProvider.ads,
    loading: loading.effects['adInvestigator/pageInit'],
    // fetchAdsLoading: loading.effects['adCampaignProvider/fetchObjectData'],
  };
}

export default connect(mapStateToProps)(AdInvestigator);
