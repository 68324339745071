import { connect } from 'dva';
import { Button, Checkbox, Form, Input, Select } from 'antd';
import { Link, routerRedux } from 'dva/router';
import { useState } from 'react';
import { Typography } from '@hulu/react-style-components';

import {
  BAD_REQUEST,
  DURATION_MANAGER_PATH,
  DURATION_MANAGER_CHANNELS,
  DURATION_MANAGER_RULE_INFO_PATH_PREFIX,
  DURATION_MANAGER_SERIES,
  DURATION_MANAGER_LEAGUES,
  RULE_TYPE,
} from '../../../constants';
import { SubPageWrapper, ButtonContainer, LinkButton, CreateForm } from '../styles';
import { labelRender } from '../utils';
import DurationFormItem from '../RuleFormItem/DurationItem';
import {
  DISPLAY_STYLE_BLOCK,
  DISPLAY_STYLE_NONE,
  RULE_CHANGE_TYPE_CREATE,
  RULE_CHANNEL_ID,
  RULE_SERIES_ID,
  RULE_LEAGUE_ID,
  RULE_EVENT_ID,
  RULE_SPORTS_ID,
  RULE_TYPE_CHANNEL,
  RULE_TYPE_SERIES,
  RULE_TYPE_LEAGUE,
  RULE_TYPE_EVENT,
  RULE_TYPE_SPORTS,
  STREAM_TYPE,
  STREAM_TYPE_LINEAR,
  STREAM_TYPE_EVENT,
} from '../constants';
import { FORM_ITEM_LAYOUT, FORM_ITEM_LAYOUT_WITHOUT_LABEL } from './styles';
import {
  DURATION_MANAGER_CREATE_BACK,
  DURATION_MANAGER_CREATE_CHANNELID,
  DURATION_MANAGER_CREATE_RULEINFO,
  DURATION_MANAGER_CREATE_RULETYPE,
  DURATION_MANAGER_CREATE_SERIESID,
  DURATION_MANAGER_CREATE_LEAGUEID,
  DURATION_MANAGER_CREATE_STREAMTYPE,
} from '../../../testUtils';
import { FORM_UUID_RULE } from '../../../utils';

const ruleTypes_1 = [
  { label: RULE_TYPE_CHANNEL, value: RULE_TYPE_CHANNEL },
  { label: RULE_TYPE_SERIES, value: RULE_TYPE_SERIES },
  { label: RULE_TYPE_LEAGUE, value: RULE_TYPE_LEAGUE },
];

const ruleTypes_2 = [
  { label: RULE_TYPE_LEAGUE, value: RULE_TYPE_LEAGUE },
  { label: RULE_TYPE_EVENT, value: RULE_TYPE_EVENT },
  { label: RULE_TYPE_SPORTS, value: RULE_TYPE_SPORTS },
];

const streamTypes = [
  { label: STREAM_TYPE_LINEAR, value: STREAM_TYPE_LINEAR },
  { label: STREAM_TYPE_EVENT, value: STREAM_TYPE_EVENT },
];

const CreateRule = ({ dispatch }) => {
  const [durationRuleType, setDurationRuleType] = useState(RULE_TYPE_CHANNEL);
  const [streamType, setStreamType] = useState(STREAM_TYPE_LINEAR);
  const [ruleExists, setRuleExists] = useState(false);
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    const requestRule = {
      'rule-type': durationRuleType,
      'stream-type': streamType,
      ...values,
    };

    const { statusCode } = await dispatch({
      type: 'durationManager/postRules',
      payload: { reqBody: [requestRule], changeType: 'Create', redirectToAllRules: true },
    });

    if (statusCode === BAD_REQUEST) {
      // We will fix the status code in the VIOLET-2005.
      setRuleExists(true);
    }
  };

  const buildRouteFromForm = (form) => {
    const CHANNEL_ROUTE = `${DURATION_MANAGER_RULE_INFO_PATH_PREFIX}/${DURATION_MANAGER_CHANNELS}/${form.getFieldValue(
      RULE_CHANNEL_ID
    )}`;
    const SERIES_ROUTE = `${CHANNEL_ROUTE}/${DURATION_MANAGER_SERIES}/${form.getFieldValue(RULE_SERIES_ID)}`;
    const LEAGUE_ROUTE = `${CHANNEL_ROUTE}/${DURATION_MANAGER_LEAGUES}/${form.getFieldValue(RULE_LEAGUE_ID)}`;
    switch (durationRuleType) {
      case RULE_TYPE_SERIES:
        return SERIES_ROUTE;
      case RULE_TYPE_LEAGUE:
        return LEAGUE_ROUTE;
      default:
        return CHANNEL_ROUTE;
    }
  };

  const onRuleInfoPageClick = () => {
    dispatch(routerRedux.push(buildRouteFromForm(form)));
  };

  const singleProgramCheckbox = (
    <Form.Item
      {...FORM_ITEM_LAYOUT}
      label={labelRender('Single Program')}
      name="is-single-program"
      valuePropName="checked"
      initialValue={false}
    >
      <Checkbox />
    </Form.Item>
  );

  const partialPodCheckbox = (
    <Form.Item
      {...FORM_ITEM_LAYOUT}
      label={labelRender('Partial Pod')}
      name="is-partial-pods"
      valuePropName="checked"
      initialValue={false}
    >
      <Checkbox />
    </Form.Item>
  );

  return (
    <SubPageWrapper>
      {/* Back Redirect */}
      <div data-testid={DURATION_MANAGER_CREATE_BACK}>
        <Link to={DURATION_MANAGER_PATH}>
          <Typography variant={'h6'}>&lt; Back to Duration Manager</Typography>
        </Link>
        <br />
        <h1>Create New Rule</h1>
        <hr />
      </div>

      {/* Form for Rule Creation */}
      <CreateForm>
        <Form
          form={form}
          onFinish={onFinish}
          initialValues={{ 'durations-ms': [''], 'stream-type': STREAM_TYPE_LINEAR }}
        >
          {/* Stream Type Fillout */}
          <div data-testid={DURATION_MANAGER_CREATE_STREAMTYPE}>
            <Form.Item
              {...FORM_ITEM_LAYOUT}
              label={labelRender('Stream Type')}
              name={STREAM_TYPE}
              rules={[{ required: true }]}
            >
              <Select options={streamTypes} onChange={setStreamType} />
            </Form.Item>
          </div>

          {/* Rule Type Fillout */}
          <div data-testid={DURATION_MANAGER_CREATE_RULETYPE}>
            <Form.Item
              {...FORM_ITEM_LAYOUT}
              label={labelRender('Rule Type')}
              name={RULE_TYPE}
              rules={[{ required: true }]}
            >
              <Select
                options={streamType === STREAM_TYPE_LINEAR ? ruleTypes_1 : ruleTypes_2}
                onChange={setDurationRuleType}
              />
            </Form.Item>
          </div>

          {/* Channel Id Fillout */}
          {streamType === STREAM_TYPE_LINEAR ? (
            <div data-testid={DURATION_MANAGER_CREATE_CHANNELID}>
              <Form.Item
                {...FORM_ITEM_LAYOUT}
                label={labelRender('Channel ID')}
                name={RULE_CHANNEL_ID}
                rules={[{ required: true, message: 'Please input channel ID' }, FORM_UUID_RULE]}
              >
                <Input />
              </Form.Item>
            </div>
          ) : null}

          {/* League Id Fillout */}
          {durationRuleType === RULE_TYPE_LEAGUE ? (
            <div data-testid={DURATION_MANAGER_CREATE_LEAGUEID}>
              <Form.Item
                {...FORM_ITEM_LAYOUT}
                label={labelRender('League ID')}
                name={RULE_LEAGUE_ID}
                rules={[{ required: true, message: 'Please input league ID' }, FORM_UUID_RULE]}
              >
                <Input />
              </Form.Item>
            </div>
          ) : null}

          {/* Event Id Fillout */}
          {durationRuleType === RULE_TYPE_EVENT ? (
            <div data-testid={DURATION_MANAGER_CREATE_LEAGUEID}>
              <Form.Item
                {...FORM_ITEM_LAYOUT}
                label={labelRender('Event ID')}
                name={RULE_EVENT_ID}
                rules={[{ required: true, message: 'Please input event ID' }, FORM_UUID_RULE]}
              >
                <Input />
              </Form.Item>
            </div>
          ) : null}

          {/* Series Id Fillout */}
          {durationRuleType === RULE_TYPE_SERIES ? (
            <div data-testid={DURATION_MANAGER_CREATE_SERIESID}>
              <Form.Item
                {...FORM_ITEM_LAYOUT}
                label={labelRender('Series ID')}
                name={RULE_SERIES_ID}
                rules={[{ required: true, message: 'Please input series ID' }, FORM_UUID_RULE]}
              >
                <Input />
              </Form.Item>
            </div>
          ) : null}

          {/* Sports Id Fillout */}
          {durationRuleType === RULE_TYPE_SPORTS ? (
            <div data-testid={DURATION_MANAGER_CREATE_SERIESID}>
              <Form.Item
                {...FORM_ITEM_LAYOUT}
                label={labelRender('Sports ID')}
                name={RULE_SPORTS_ID}
                rules={[{ required: true, message: 'Please input sports ID' }, FORM_UUID_RULE]}
              >
                <Input />
              </Form.Item>
            </div>
          ) : null}

          {/* Single Program checkbox (Channel Rule Only) */}
          {durationRuleType === RULE_TYPE_CHANNEL ? singleProgramCheckbox : null}
          {streamType === STREAM_TYPE_LINEAR || streamType === STREAM_TYPE_EVENT ? partialPodCheckbox : null}
          <DurationFormItem layout={FORM_ITEM_LAYOUT} layoutWithoutLabel={FORM_ITEM_LAYOUT_WITHOUT_LABEL} />

          {/* Submit */}
          <div data-testid={DURATION_MANAGER_CREATE_RULEINFO}>
            <Form.Item {...FORM_ITEM_LAYOUT_WITHOUT_LABEL}>
              <ButtonContainer>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </ButtonContainer>
            </Form.Item>
          </div>

          {/* Rule Info */}
          <Form.Item {...FORM_ITEM_LAYOUT_WITHOUT_LABEL}>
            <h3 style={{ display: ruleExists ? DISPLAY_STYLE_BLOCK : DISPLAY_STYLE_NONE }}>
              This rule already exists! Go to the <LinkButton onClick={onRuleInfoPageClick}>Rule Info</LinkButton> page
              to edit it instead.
            </h3>
          </Form.Item>
        </Form>
      </CreateForm>
    </SubPageWrapper>
  );
};

export default connect()(CreateRule);
