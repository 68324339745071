import { connect } from 'dva';
import React, { useState, useEffect, useRef, createRef, useMemo } from 'react';
import { StyledDropdownButton, StyledMenu, StyledMenuOutlined, ToolSelectionLi } from './styles';
import { TOOLS_LIST, VIEW } from '../../constants';
import { routerRedux } from 'dva/router';
import { Button } from '@hulu/react-style-components';

// follows Navigation Menu Button Pattern: https://www.w3.org/WAI/ARIA/apg/patterns/menu-button/examples/menu-button-links/
const PageNavDropdown = ({ dispatch, permissions, authenticated }) => {
  const ref = useRef(null);
  const menuBtnRef = createRef();
  const ulRef = createRef();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [selectedToolIdx, setSelectedToolIdx] = useState(-1);

  const toggleViewMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const onClickDropdown = (e) => {
    toggleViewMenu();

    // focus on first list item
    setSelectedToolIdx(0);
  };

  const escape = () => {
    setMenuOpen(false);
    menuBtnRef.current.focus();
  };

  const onDropdownKeyDown = (e) => {
    if (e.code === 'ArrowDown') {
      toggleViewMenu();

      // focus on first list item
      setSelectedToolIdx(0);
    } else if (e.code === 'ArrowUp') {
      toggleViewMenu();

      // focus on last list item
      setSelectedToolIdx(items.length - 1);
    } else if (e.code === 'Escape') {
      escape();
    }
  };

  const onKeyNavigation = (e) => {
    if (e.code === 'ArrowDown') {
      // move down to next button, or loop around
      let newIdx = selectedToolIdx + 1;
      if (newIdx >= items.length) {
        newIdx = 0;
      }
      setSelectedToolIdx(newIdx);
    } else if (e.code === 'ArrowUp') {
      // move up to previous button, or loop around
      let newIdx = selectedToolIdx - 1;
      if (newIdx < 0) {
        newIdx = items.length - 1;
      }
      setSelectedToolIdx(newIdx);
    } else if (e.keyCode === 36) {
      // home key - move to first menu item
      setSelectedToolIdx(0);
    } else if (e.keyCode === 35) {
      // end key - move to last menu item
      setSelectedToolIdx(items.length - 1);
    } else if (e.keyCode >= 65 && e.keyCode <= 90) {
      // focus on first button starting with pressed letter
      let currentIdx = selectedToolIdx;
      let found = false;
      while (!found && currentIdx < items.length) {
        if (items[currentIdx].key.charAt(0).toString() === e.key) {
          setSelectedToolIdx(currentIdx);
          found = true;
        }
        currentIdx++;
      }
      currentIdx = 0;
      while (!found && currentIdx < selectedToolIdx) {
        if (items[currentIdx].key.charAt(0) === e.key) {
          setSelectedToolIdx(currentIdx);
          found = true;
        }
        currentIdx++;
      }
    } else if (e.code === 'Escape') {
      escape();
    }
  };

  const onClickTool = (e) => {
    dispatch(routerRedux.push('/' + e));
    setMenuOpen(false);
  };

  const items = useMemo(() => {
    if (authenticated) {
      return Object.entries(TOOLS_LIST)
        .filter(([key, obj]) => permissions.can(VIEW, key))
        .map(([key, obj], index, array) => {
          return { label: obj.title, key: key };
        });
    }
  }, [authenticated, permissions]);
  let menuItems = authenticated ? (
    items.map((item) => {
      return (
        <ToolSelectionLi key={item.key}>
          <Button id={item.key} onKeyDown={onKeyNavigation} onClick={() => onClickTool(item.key)}>
            {item.label}
          </Button>
        </ToolSelectionLi>
      );
    })
  ) : (
    <></>
  );

  useEffect(() => {
    if (selectedToolIdx >= 0 && selectedToolIdx < items?.length && isMenuOpen) {
      ulRef.current.childNodes.forEach((listItem) => {
        if (listItem.childNodes[0].id === items[selectedToolIdx].key) {
          // focus on button
          listItem.childNodes[0].focus();
        }
      });
    }

    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [ref, isMenuOpen, selectedToolIdx, items, ulRef]);

  return (
    <div ref={ref}>
      <StyledDropdownButton
        ref={menuBtnRef}
        onClick={onClickDropdown}
        onKeyDown={onDropdownKeyDown}
        type="button"
        id="menubutton"
        aria-haspopup="true"
        aria-controls="IDREF"
        aria-expanded={isMenuOpen}
      >
        <StyledMenuOutlined />
      </StyledDropdownButton>
      <StyledMenu isOpen={isMenuOpen}>
        <ul ref={ulRef}>{menuItems}</ul>
      </StyledMenu>
    </div>
  );
};

function mapStateToProps({ app }) {
  return {
    permissions: app.currentUser.permissions,
    authenticated: app.authenticated,
  };
}

export default connect(mapStateToProps)(PageNavDropdown);
