import { Button, Form, Input, Radio, Space, Spin, Alert } from 'antd';
import { connect } from 'dva';
import React, { useEffect, useState } from 'react';
import { Q_CHECK_MEDIA_FILE_URL_SUBMIT, Q_CHECK_MEDIA_FILE_URL_FORM, Q_CHECK_ERROR } from '../../testUtils';
import { MediaFileUrlWrapper } from './styles';
import { FORM_NOT_EMPTY_RULE } from '../../utils';
import { COMPLETE, FAILURE, LOADING, MEDIA_FILE, MEDIA_FILE_URL } from './constants';

const MediaFileValidationBox = ({ dispatch, setMediaFileValidating, meidaFileValidationError }) => {
  const [form] = Form.useForm();
  const [uploadMethod, setUploadMethod] = useState(MEDIA_FILE_URL); // either MEDIA_FILE_URL or MEDIA_FILE
  const [uploadStatus, setUploadStatus] = useState(null);
  const [uploadUrl, setUploadUrl] = useState(null);

  const handleFileUpload = async (e) => {
    setUploadStatus(LOADING);
    let file = e.target.files[0];
    if (file) {
      let result = await dispatch({
        type: 'qCheck/saveMediaFile',
        payload: file,
      });
      if (result) {
        setUploadStatus(result.urlStatus);
        setUploadUrl(result.url);
      } else {
        setUploadStatus(FAILURE);
        setUploadUrl(null);
      }
    } else {
      // if user cancels upload, clear url
      setUploadStatus(null);
      setUploadUrl(null);
    }
  };

  const onFinish = async (value) => {
    setMediaFileValidating(true);
    if (uploadMethod === MEDIA_FILE_URL) {
      await dispatch({
        type: 'qCheck/validateMediaFile',
        payload: value.payload,
      });
    } else if (uploadUrl) {
      await dispatch({
        type: 'qCheck/validateMediaFile',
        payload: uploadUrl,
      });
      setUploadStatus(null);
      setUploadUrl(null);
    }
    setMediaFileValidating(false);
  };

  const resetFields = () => {
    dispatch({ type: 'qCheck/resetMediaFileTab' });
    form.resetFields();
  };

  useEffect(() => {
    return () => {
      // reset fields during unmounting
      resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateUploadMethod = (e) => {
    setUploadMethod(e.target.value);
    setUploadStatus(null);
    setUploadUrl(null);
    resetFields();
  };

  const onUrlFormChange = (e) => {
    if (e.target.value !== null && e.target.value !== '') {
      setUploadStatus(COMPLETE);
    } else {
      setUploadStatus(null);
    }
  };

  const mediaUploadSelection = (
    <div>
      <p>
        <b>Choose media file upload method:</b>
      </p>
      <Radio.Group onChange={updateUploadMethod} defaultValue={MEDIA_FILE_URL}>
        <Radio value={MEDIA_FILE_URL}>URL</Radio>
        <Radio value={MEDIA_FILE}>Media File</Radio>
      </Radio.Group>
    </div>
  );

  const urlForm = (
    <Form.Item
      name="payload"
      label="Media File URL"
      rules={[{ required: true, message: 'Please input media file URL' }, FORM_NOT_EMPTY_RULE]}
      onChange={onUrlFormChange}
    >
      <Input placeholder="Media File URL" />
    </Form.Item>
  );

  const fileForm = (
    <Form.Item name="file-upload" label={'Media File Upload'}>
      <>
        <Input type="file" onChange={handleFileUpload} accept={'.mov,.mp4'} />
        {uploadStatus === LOADING ? (
          <>
            <Spin />
            <p>File uploading</p>
          </>
        ) : null}
        {uploadStatus === COMPLETE ? (
          <>
            <p>File upload complete</p>
          </>
        ) : null}
        {uploadStatus === FAILURE ? (
          <>
            <Spin />
            <p>File upload failed</p>
          </>
        ) : null}
      </>
    </Form.Item>
  );

  const mediaFileValidationErrorMessage = meidaFileValidationError ? (
    <Form.Item>
      <div data-testid={Q_CHECK_ERROR}>
        <Alert
          message={meidaFileValidationError.errorMessage}
          description={`Detail:${meidaFileValidationError.errorDetail}`}
          type="error"
          showIcon
          style={{ marginBottom: '20px' }}
        />
      </div>
    </Form.Item>
  ) : null;

  const submitButton = (
    <Form.Item>
      <div data-testid={Q_CHECK_MEDIA_FILE_URL_SUBMIT}>
        <Space>
          <Button type="primary" htmlType="submit" disabled={uploadStatus !== COMPLETE}>
            Run Quality Check
          </Button>
        </Space>
      </div>
    </Form.Item>
  );

  return (
    <MediaFileUrlWrapper>
      <div data-testid={Q_CHECK_MEDIA_FILE_URL_FORM}>
        <Form form={form} onFinish={onFinish} layout="vertical">
          {mediaUploadSelection}
          <br />
          {uploadMethod === MEDIA_FILE_URL && urlForm}
          {uploadMethod === MEDIA_FILE && fileForm}
          {mediaFileValidationErrorMessage}
          {submitButton}
        </Form>
      </div>
    </MediaFileUrlWrapper>
  );
};

function mapStateToProps({ loading, qCheck, app }) {
  return {
    loading: loading.effects['qCheck/pageInit'],
    pathParams: app.pathParams,
  };
}

export default connect(mapStateToProps)(MediaFileValidationBox);
