// count number of capture groups in a regular expression
// export for test
export const numCaptureGroups = (regex) => {
  // match regex OR '' with '', count capture groups
  // (thanks: https://stackoverflow.com/questions/16046620/regex-to-count-the-number-of-capturing-groups-in-a-regex)
  const groups = new RegExp(`${regex.toString()}|`).exec('');
  return groups ? groups.length - 1 : 0;
};

// return substring at specified captureGroup if it exists (otherwise return null, the result of .match() not matching)
// note that this is not 0-indexed, capture group 1 is the first capture group. There is no capture group 0 (or... there
// is, but it's just the original string `str` that you passed in)
// i.e. for `str` === `abc(test)def(return me)`, capture group 1 is 'test' and capture group 2 is 'return me'
export const subString = (str, regex, captureGroup) => {
  // if captureGroup is out of bounds, return null
  if (captureGroup < 0 || captureGroup > numCaptureGroups(regex)) return null;

  const match = str.match(regex);
  return match && match[captureGroup];
};
