import React, { memo, useState } from 'react';
import { Collapse, List, Drawer } from 'antd';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { SearchOutlined } from '@ant-design/icons';
import { colors } from '@hulu/design-tokens';
import { IconButton } from '@hulu/react-style-components';

import {
  AUCTION_SNAPSHOT_DEVICES_FILTER,
  AUCTION_SNAPSHOT_APP_NAMES_FILTER,
  AUCTION_SNAPSHOT_COUNTRY_FILTER,
} from '../../../../constants';
import { SearchInput } from '../SearchInput';

export const StyledCloseButton = styled(IconButton)`
  width: auto;
  height: auto;
  border: none;
  margin: 0 0 0 auto;
`;
export const StyledDrawer = styled(Drawer)`
  .ant-drawer-header {
    padding: 25px 20px;
  }
  .ant-drawer-header-title {
    flex-direction: row-reverse;
    .drawer-title-text {
      font-weight: normal;
    }
  }
  .ant-drawer-body {
    padding: 0;
  }
`;

const { Panel } = Collapse;

const StyledFiltersInput = styled.div`
  padding: 15px;
`;
const StyledCollapse = styled(Collapse)`
  border-width: 1px 0 0 0;
  .ant-collapse-item {
    .ant-collapse-header {
      height: 32px;
      padding: 5px 16px 5px 13px !important;
      .ant-collapse-arrow {
        top: -2px;
      }
    }
  }
  .ant-collapse-header-text {
    width: 100%;
  }
  .filter-panel-header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    height: 24px;
    font-size: 12px;
    font-weight: bold;
    width: 100%;
  }
  .filter-panel-clear {
    color: #176ee1;
    text-transform: uppercase;
  }
  .filter-search {
    padding: 10px 5px 0;
    .ant-input-affix-wrapper {
      margin: 0 0 5px;
    }
  }
  .ant-collapse-content {
    .ant-collapse-content-box {
      padding: 0;
    }
  }
`;
const StyledListItem = styled(List.Item)`
  box-sizing: border-box;
  height: 36px;
  padding: 9px 0 9px 32px;
  cursor: pointer;
  font-size: 12px;
  &.active,
  &:hover {
    background: ${colors.colorBlue05};
  }
`;
const StyledList = styled(List)`
  ul {
    max-height: 20vh;
    overflow: auto;
  }
`;

const devicePanel = 'device-panel';
const appNamesPanel = 'app-names-panel';
const countryPanel = 'country-panel';
const emptySearchResult = 'No results found';

const CONTENT_PANEL_MAPPER = {
  [AUCTION_SNAPSHOT_DEVICES_FILTER]: {
    panelName: 'Device Family',
    panelKey: devicePanel,
    searchPlaceholder: 'Search devices',
  },
  [AUCTION_SNAPSHOT_APP_NAMES_FILTER]: {
    panelName: 'Publisher',
    panelKey: appNamesPanel,
    searchPlaceholder: 'Search publisher',
  },
  [AUCTION_SNAPSHOT_COUNTRY_FILTER]: {
    panelName: 'Country',
    panelKey: countryPanel,
    searchPlaceholder: 'Search country',
  },
};

const PanelHeader = ({ panelName, filterLength, onFiltersClear }) => {
  return (
    <div className="filter-panel-header">
      <span className="filter-panel-name">
        {panelName} ({filterLength || 0})
      </span>
      {filterLength > 0 && (
        <span className="filter-panel-clear" onClick={onFiltersClear}>
          Clear
        </span>
      )}
    </div>
  );
};
const PanelList = ({ listState, activeFilters, type, handleFilters }) => {
  return (
    <StyledList
      dataSource={listState[type]}
      locale={{ emptyText: emptySearchResult }}
      renderItem={(item) => (
        <StyledListItem
          key={item}
          onClick={() => listState[type]?.length !== 0 && handleFilters(type, item)}
          className={activeFilters[type]?.includes(item) && 'active'}
        >
          {item}
        </StyledListItem>
      )}
    />
  );
};

const prefix = <SearchOutlined style={{ fontSize: '18px' }} />;

const Content = ({
  activePanel,
  listState,
  activeFilters,
  handleFilters,
  onCollapsePanelChange,
  onSearch,
  onFiltersClear,
}) => {
  return (
    <StyledCollapse activeKey={activePanel} onChange={onCollapsePanelChange}>
      {Object.entries(CONTENT_PANEL_MAPPER).map(([key, { panelName, panelKey, searchPlaceholder }]) => {
        return (
          <Panel
            header={
              <PanelHeader
                panelName={panelName}
                filterLength={activeFilters?.[key]?.length}
                onFiltersClear={() => onFiltersClear(key)}
              />
            }
            key={panelKey}
          >
            <div className="filter-search">
              <SearchInput
                placeholder={searchPlaceholder}
                prefix={prefix}
                width="100%"
                onChange={(query) => onSearch(query, key)}
              />
            </div>
            <PanelList listState={listState} activeFilters={activeFilters} type={key} handleFilters={handleFilters} />
          </Panel>
        );
      })}
    </StyledCollapse>
  );
};

const FilterSnapshotsDropdownComponent = (props) => {
  const { data, activeFilters, handleFilters, onFiltersClear, isOpen, onClose } = props;
  const [listState, setListState] = useState(data);
  const [activePanel, setActivePanel] = useState([]);
  const onAllSearch = (query) => {
    setActivePanel([devicePanel, appNamesPanel, countryPanel]);
    onSearch(query, AUCTION_SNAPSHOT_DEVICES_FILTER);
    onSearch(query, AUCTION_SNAPSHOT_APP_NAMES_FILTER);
    onSearch(query, AUCTION_SNAPSHOT_COUNTRY_FILTER);
  };

  const onCollapsePanelChange = (key) => setActivePanel(key);

  const onSearch = (query, type) => {
    if (query !== '') {
      const results = data[type].filter((item) => {
        return item.toLowerCase().startsWith(query.toLowerCase());
      });
      if (results.length) {
        setListState((prev) => ({ ...prev, [type]: results }));
      } else {
        setListState((prev) => ({ ...prev, [type]: [] }));
      }
    } else {
      setListState(data);
    }
  };

  return (
    <StyledDrawer
      placement="left"
      open={isOpen}
      onClose={onClose}
      destroyOnClose
      title={<span>Filters</span>}
      width={600}
      closeIcon={<StyledCloseButton variant="outlined" icon="IconClose" />}
    >
      <StyledFiltersInput>
        <SearchInput
          allowClear
          placeholder="Search all filters"
          prefix={<SearchOutlined style={{ fontSize: '18px' }} />}
          onChange={onAllSearch}
          width="100%"
        />
      </StyledFiltersInput>
      <Content
        activePanel={activePanel}
        listState={listState}
        activeFilters={activeFilters}
        handleFilters={handleFilters}
        onCollapsePanelChange={onCollapsePanelChange}
        onSearch={onSearch}
        onFiltersClear={onFiltersClear}
      />
    </StyledDrawer>
  );
};

FilterSnapshotsDropdownComponent.propTypes = {
  data: PropTypes.object.isRequired,
  activeFilters: PropTypes.object.isRequired,
  handleFilters: PropTypes.func.isRequired,
  onFiltersClear: PropTypes.func.isRequired,
  disabled: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export const FilterSnapshotsDropdown = memo(FilterSnapshotsDropdownComponent);
