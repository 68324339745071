import { useState, useEffect, useCallback } from 'react';

import {
  BAD_REQUEST,
  SERVER_ERROR,
  AUCTION_SNAPSHOT_DEVICES_FILTER,
  AUCTION_SNAPSHOT_APP_NAMES_FILTER,
  AUCTION_SNAPSHOT_COUNTRY_FILTER,
  EASTERN_TIME_ZONE,
  AUCTION_SNAPSHOT_TIMEZONE_FORMAT,
} from '../../constants';
import {
  AUCTION_SNAPSHOT_TABLE_DEFAULT_PAGE,
  AUCTION_SNAPSHOT_TABLE_DEFAULT_PAGE_SIZE,
  USER_OPTIONS_ALL,
  MY_SNAPSHOTS,
  ALL_SNAPSHOTS,
} from './constants';
import { openNotificationWithIcon } from './notifications';
import {
  getSnapshots,
  getSnapshotById,
  deleteSnapshot,
  getSnapshotParameters,
  getUsers,
  editSnapshotName,
} from '../../services/auctionSnapshot';

const filtersEmpty = {
  [AUCTION_SNAPSHOT_DEVICES_FILTER]: [],
  [AUCTION_SNAPSHOT_APP_NAMES_FILTER]: [],
  [AUCTION_SNAPSHOT_COUNTRY_FILTER]: [],
};

const ALL_OPTION = { label: ALL_SNAPSHOTS, value: USER_OPTIONS_ALL };

export const INITIAL_FILTERS_STATE = {
  search: null,
  deviceFamily: [],
  appName: [],
  country: [],
  page: AUCTION_SNAPSHOT_TABLE_DEFAULT_PAGE + 1,
  size: AUCTION_SNAPSHOT_TABLE_DEFAULT_PAGE_SIZE,
  sort: ['date', 'descend'],
  dateValue: [],
  selectedUserOptions: [],
};

const getExpandedSnapshotRowKeys = (snapshots, searchValue) => {
  if (!searchValue) {
    return [];
  }
  return snapshots?.content?.reduce((expandedIds, snapshot) => {
    snapshot?.auctions?.forEach((auction) => {
      if (auction?.externalAuctionId?.toLowerCase()?.trim()?.includes(searchValue?.toLowerCase()?.trim())) {
        expandedIds = [...expandedIds, snapshot?.id];
      }
    });
    return expandedIds;
  }, []);
};

export const useAuctionSnapshot = (currentUserEmail) => {
  const [filtersState, setFiltersState] = useState(INITIAL_FILTERS_STATE);
  const [snapshotParameters, setSnapshotParameters] = useState({});
  const [users, setUsers] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [snapshots, setSnapshots] = useState({});

  const [snapshotsLoadingError, setSnapshotsLoadingError] = useState(null);
  const [selectedSnapshot, setSelectedSnapshot] = useState(null);
  const [snapshotToDelete, setSnapshotToDelete] = useState(null);

  const [isSnapshotsLoading, setIsSnapshotsLoading] = useState(false);
  const [isAdRequestOpen, setIsAdRequestOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isProfilesOpen, setIsProfilesOpen] = useState(false);

  const [snapshotExpandedKeys, setSnapshotExpandedKeys] = useState([]);

  const { page, size, deviceFamily, appName, country, search, dateValue, sort, selectedUserOptions } = filtersState;

  const pagination = { page, size };
  const activeFilters = { deviceFamily, appName, country };

  const fetchAuctionSnapshots = useCallback(
    (filtersState) => {
      const { page, size, deviceFamily, appName, country, search, dateValue, sort, selectedUserOptions } = filtersState;

      setIsSnapshotsLoading(true);

      if (users.length) {
        const orderPartToRemove = 'end';
        const sortToSend = sort ? `${sort[0]},${sort[1].slice(0, -orderPartToRemove.length)}` : null;
        const userId = selectedUserOptions[0] === USER_OPTIONS_ALL ? [] : selectedUserOptions;

        getSnapshots({
          dateAfter: dateValue[0]?.tz(EASTERN_TIME_ZONE).startOf('day').format(AUCTION_SNAPSHOT_TIMEZONE_FORMAT),
          dateBefore: dateValue[1]?.tz(EASTERN_TIME_ZONE).endOf('day').format(AUCTION_SNAPSHOT_TIMEZONE_FORMAT),
          page,
          size,
          deviceFamily,
          appName,
          country,
          userId,
          search,
          sort: sortToSend,
        })
          .then((snapshots) => {
            setSnapshots(snapshots);
            setSnapshotExpandedKeys(() => getExpandedSnapshotRowKeys(snapshots, search));
          })
          .catch(setSnapshotsLoadingError)
          .finally(() => setIsSnapshotsLoading(false));
      }
    },
    [users]
  );

  const applyUsers = (users, currentUserEmail) => {
    const me = users.find((user) => user.email.toLowerCase() === currentUserEmail.toLowerCase());
    const meOption = me ? { label: MY_SNAPSHOTS, value: me.id } : null;
    const filteredUsers = users.filter(
      (userOption) => userOption.email.toLowerCase() !== currentUserEmail.toLowerCase()
    );
    const filteredOptions = filteredUsers.map((item) => ({
      label: `${item.firstName} ${item.secondName}`,
      value: item.id,
    }));
    const options = me ? [meOption, ALL_OPTION, ...filteredOptions] : [ALL_OPTION, ...filteredOptions];

    setUserOptions(options);
    setFiltersState((prevState) => ({ ...prevState, selectedUserOptions: me ? [me.id] : [USER_OPTIONS_ALL] }));
  };

  const getFiltersDropdownData = () => {
    return (
      snapshotParameters?.snapshotSearchParametersBySupplySource?.reduce((acc, curr) => {
        const deviceFamily = [...new Set(acc.deviceFamily.concat(curr.deviceFamily))];
        const appName = [...new Set(acc.appName.concat(curr.appName))];
        const country = curr.country ? [...new Set(acc.country.concat(curr.country))] : acc.country;
        return { deviceFamily, appName, country };
      }, filtersEmpty) || filtersEmpty
    );
  };

  const openDeleteSnapshotModal = (selectedSnapshot) => {
    setSnapshotToDelete(selectedSnapshot);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteSnapshotModal = () => {
    setSnapshotToDelete(null);
    setIsDeleteModalOpen(false);
  };

  const onSnapshotsRefresh = () => fetchAuctionSnapshots({ ...filtersState, page: page - 1, size });

  const onDeleteSnapshot = async () => {
    try {
      await deleteSnapshot({ snapshotId: snapshotToDelete.id });
      const pageToSet = !snapshots.first && snapshots.totalElements - 1 <= (page - 1) * size ? page - 1 : page;
      const sizeToSet = size;

      setFiltersState((prevState) => ({ ...prevState, page: pageToSet, size: sizeToSet }));

      openNotificationWithIcon('success', `"${snapshotToDelete.name}" snapshot was successfully deleted.`, true);
    } catch (err) {
      if (err.response.status === BAD_REQUEST) {
        openNotificationWithIcon('error', `"${snapshotToDelete.name}" snapshot deletion failed.`, true);
      }
      if (err.response.status >= SERVER_ERROR) {
        openNotificationWithIcon('error', `Something went wrong, please try again later.`, true);
      }
    } finally {
      setSnapshotToDelete(null);
      closeDeleteSnapshotModal();
    }
  };

  const onSnapshotExpand = (expanded, { id }) => {
    setSnapshotExpandedKeys((prevExpandedKeys) =>
      expanded ? prevExpandedKeys.concat(id) : prevExpandedKeys.filter((k) => k !== id)
    );
  };

  const openAdRequestDrawer = () => setIsAdRequestOpen(true);
  const closeAdRequestDrawer = () => setIsAdRequestOpen(false);

  const openProfiles = () => setIsProfilesOpen(true);
  const closeProfiles = () => setIsProfilesOpen(false);

  const onTableChange = (pagination, _, sorter) => {
    const { current, pageSize } = pagination;
    const { order, columnKey } = sorter;
    setFiltersState((prevState) => {
      const pageToSet = pageSize === prevState.size ? current : AUCTION_SNAPSHOT_TABLE_DEFAULT_PAGE + 1;
      return {
        ...prevState,
        page: pageToSet,
        size: pageSize,
        sort: [columnKey, order],
      };
    });
  };

  const onAllFiltersClear = () => {
    setFiltersState(INITIAL_FILTERS_STATE);
    applyUsers(users, currentUserEmail);
  };

  const onFiltersClear = (type) => {
    setFiltersState((prevState) => ({
      ...prevState,
      [type]: [],
    }));
  };

  const onDeleteFilter = (type, filterName) => {
    const res = activeFilters[type].filter((item) => item !== filterName);
    setFiltersState((prevState) => ({
      ...prevState,
      [type]: res,
    }));
  };

  const onClearAppNameFilter = () => {
    setFiltersState((prevState) => ({
      ...prevState,
      appName: [],
    }));
  };

  const onRerunSnapshot = async ({ id }) => {
    try {
      const snapshot = await getSnapshotById(id);
      setSelectedSnapshot(snapshot);
      openAdRequestDrawer();
    } catch (err) {
      openNotificationWithIcon('error', err, true);
    }
  };

  const handleFilters = (type, filterName) => {
    if (!activeFilters[type]?.includes(filterName)) {
      const res = [...activeFilters[type], filterName];
      setFiltersState((prevState) => ({
        ...prevState,
        [type]: res,
        page: AUCTION_SNAPSHOT_TABLE_DEFAULT_PAGE + 1,
      }));
    } else {
      onDeleteFilter(type, filterName);
    }
  };

  const onUsersSearch = (searchValue) => {
    const me = users.find((user) => user.email.toLowerCase() === currentUserEmail.toLowerCase());
    const usersWithoutMe = me
      ? users.filter((user) => user.email.toLowerCase() !== currentUserEmail.toLowerCase())
      : users;
    const meOption = me ? { label: MY_SNAPSHOTS, value: me.id } : null;
    const filteredUserOptions = usersWithoutMe.map((user) => ({
      label: `${user.firstName} ${user.secondName}`,
      value: user.id,
    }));
    const options = me ? [meOption, ALL_OPTION, ...filteredUserOptions] : [ALL_OPTION, ...filteredUserOptions];
    const searchedOptions = options.filter((option) => option.label.toLowerCase().includes(searchValue));

    setUserOptions(searchedOptions);
  };

  const handleUserSnapshotValue = (selected) => {
    const lastSelected = selected[selected.length - 1];
    const selectedUsers = users.filter((user) => selected.includes(user.id));
    const meSelected = selectedUsers.find((user) => user.email.toLowerCase() === currentUserEmail.toLowerCase());
    const allSelected = lastSelected === USER_OPTIONS_ALL;

    if (allSelected) {
      setFiltersState((prevState) => ({
        ...prevState,
        selectedUserOptions: [USER_OPTIONS_ALL],
        page: AUCTION_SNAPSHOT_TABLE_DEFAULT_PAGE + 1,
      }));
      return;
    }

    if (meSelected) {
      setFiltersState((prevState) => ({
        ...prevState,
        selectedUserOptions: [lastSelected],
        page: AUCTION_SNAPSHOT_TABLE_DEFAULT_PAGE + 1,
      }));
      return;
    }

    if (selected.length === 0) {
      applyUsers(users, currentUserEmail);
      return;
    }

    setFiltersState((prevState) => ({
      ...prevState,
      selectedUserOptions: selected.filter((item) => item !== USER_OPTIONS_ALL),
      page: AUCTION_SNAPSHOT_TABLE_DEFAULT_PAGE + 1,
    }));
  };

  const onSearchAuctionSnapshots = (query) =>
    setFiltersState((prevState) => ({
      ...prevState,
      search: query || null,
      page: AUCTION_SNAPSHOT_TABLE_DEFAULT_PAGE + 1,
    }));

  const handleDateRange = (value) =>
    setFiltersState((prevState) => ({
      ...prevState,
      dateValue: value || INITIAL_FILTERS_STATE.dateValue,
      page: AUCTION_SNAPSHOT_TABLE_DEFAULT_PAGE + 1,
    }));

  const handleInlineEditName = async (snapshotId, newName, oldName) => {
    const trimmedName = newName?.trim();
    try {
      await editSnapshotName(snapshotId, trimmedName);
      onSnapshotsRefresh();

      openNotificationWithIcon(
        'success',
        `"${oldName}" snapshot has been renamed to "${trimmedName}" successfully`,
        true
      );
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    getUsers().then((users) => {
      applyUsers(users, currentUserEmail);
      setUsers(users);
    });
  }, [currentUserEmail]);

  useEffect(() => {
    getSnapshotParameters().then((data) => setSnapshotParameters(data));
  }, []);

  useEffect(() => {
    fetchAuctionSnapshots({
      ...filtersState,
      page: page - 1,
      size,
    });
  }, [fetchAuctionSnapshots, filtersState, page, size]);

  return {
    isAdRequestOpen,
    isDeleteModalOpen,
    isProfilesOpen,
    isSnapshotsLoading,
    snapshotsLoadingError,
    snapshots,
    selectedSnapshot,
    snapshotToDelete,
    pagination,
    activeFilters,
    search,
    dateValue,
    sort,
    userOptions,
    selectedUserOptions,
    snapshotParameters,
    snapshotExpandedKeys,

    openAdRequestDrawer,
    closeAdRequestDrawer,
    onRerunSnapshot,
    openProfiles,
    closeProfiles,
    openDeleteSnapshotModal,
    closeDeleteSnapshotModal,
    setSelectedSnapshot,
    onDeleteSnapshot,
    onSnapshotExpand,
    onSnapshotsRefresh,
    onTableChange,
    onAllFiltersClear,
    onFiltersClear,
    onDeleteFilter,
    onClearAppNameFilter,
    handleFilters,
    handleUserSnapshotValue,
    onSearchAuctionSnapshots,
    handleDateRange,
    onUsersSearch,
    getFiltersDropdownData,
    handleInlineEditName,
  };
};
