import { getAssetsData, getTargetingRulesData } from '../../services/campaignSearcher';
import { ADS, BASE_ASSETS, CAMPAIGNS, LINE_ITEMS, TARGETING_RULES } from '../../constants';

const initState = {
  [ADS]: {},
  [LINE_ITEMS]: {},
  [CAMPAIGNS]: {},
  [TARGETING_RULES]: {},
  [BASE_ASSETS]: {},
};

export const adCampaignProvider = {
  namespace: 'adCampaignProvider',
  state: initState,
  reducers: {
    updateObject(state, { payload: { objectName, objects } }) {
      state[objectName] = objects;
      return state;
    },
  },
  effects: {
    *pageInit(emptyPayload, { put }) {
      for (const objectName of [ADS, LINE_ITEMS, CAMPAIGNS, TARGETING_RULES, BASE_ASSETS]) {
        yield put({ type: 'fetchObjectData', payload: objectName });
      }
    },
    *fetchObjectData({ payload: objectName }, { call, put }) {
      let resp;
      switch (objectName) {
        case TARGETING_RULES:
          resp = yield call(getTargetingRulesData);
          break;
        case BASE_ASSETS:
          resp = yield call(getAssetsData);
          break;
        default:
        // TODO undo comment in https://jira.disneystreaming.com/browse/VIOLET-3082
        // resp = yield call(getAdCampaignData, objectName);
      }

      if (resp?.data?.data) {
        const objects = resp.data.data.reduce((map, object) => {
          map[object.id] = object;
          return map;
        }, {});
        yield put({ type: 'updateObject', payload: { objectName, objects } });
      }
    },
  },
};
