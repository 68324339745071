import moment from 'moment';
import clone from 'clone-deep';

import { getAssetDetails, getContentPartnerIdentifiers, getCreatives } from '../../services/creativeTracker';
import { DATA_TYPE, SELECT_MODE, STATUSES } from '../../constants';
import { translateAssetDetailsToTimeline } from './assetUtils';
import { subString } from './regexUtils';
import {
  AD_SERVER,
  AD_SERVER_OPTIONS,
  formatCpOptions,
  INGESTION_STATUS_OPTIONS,
  INGESTION_TYPE_OPTIONS,
  VAST_TYPE_OPTIONS,
} from './constants';
import { ACCESS_AD_SERVER, ACCESS_CONTENT_PARTNER, ALL } from '../constants';
import { currentUserSelector } from '../app';
import { FORM_NUMBERS_RULE } from '../../utils';

const BEGINNING_OF_LAST_WEEK = moment().startOf('day').subtract(1, 'weeks');
const END_OF_TODAY = moment().endOf('day');

export const INITIAL_FILTER_STATE = {
  adUnitIds: {
    selected: [],
    options: [],
  },
  adSystems: {
    selected: [],
    options: [],
  },
  advertisers: {
    selected: [],
    options: [],
  },
  adServers: {
    selectMode: SELECT_MODE.MULTIPLE,
    selected: [],
    options: [],
  },
  statuses: {
    selectMode: SELECT_MODE.MULTIPLE,
    selected: [],
    options: INGESTION_STATUS_OPTIONS,
  },
  lastSeenRange: {
    selected: [BEGINNING_OF_LAST_WEEK, END_OF_TODAY],
  },
  contentPartnerIdentifiers: {
    selected: [],
    options: [],
  },
  campaignManagerAccountNames: {
    selected: [],
    options: [],
  },
  mediafileIdentifiers: {
    selected: [],
    options: [],
  },
  vastTypes: {
    selectMode: SELECT_MODE.MULTIPLE,
    selected: [],
    options: VAST_TYPE_OPTIONS,
  },
  vastUrls: {
    selected: [],
    options: [],
  },
  ingestionTypes: {
    selectMode: SELECT_MODE.MULTIPLE,
    selected: [],
    options: INGESTION_TYPE_OPTIONS,
  },
  assetIds: {
    selected: [],
    options: [],
    dataType: DATA_TYPE.NUMBER,
    rules: [{ required: false }, { type: 'array', max: 5 }, FORM_NUMBERS_RULE],
  },
};

export const INITIAL_SELECTED_VALUES = Object.entries(INITIAL_FILTER_STATE).reduce(
  (accumulator, [field, data]) => ({ ...accumulator, [field]: data.selected }),
  {}
);

export const filterSelectedSelector = (state) => {
  const filterSelected = {};
  Object.entries(state.creativeTracker.filters).forEach(([field, data]) => {
    if (field.endsWith('Range')) {
      filterSelected[field.replace('Range', 'From')] = data.selected?.[0];
      filterSelected[field.replace('Range', 'To')] = data.selected?.[1];
    } else {
      filterSelected[field] = data.selected;
    }
  });
  return filterSelected;
};

const getContentPartners = (contentPartners, abilities) =>
  contentPartners.filter(
    (partner) =>
      abilities.can(ACCESS_CONTENT_PARTNER, ALL) || abilities.can(ACCESS_CONTENT_PARTNER, partner.name.toLowerCase())
  );

const getAdServers = (abilities) =>
  AD_SERVER_OPTIONS.filter(
    (adServer) => abilities.can(ACCESS_AD_SERVER, ALL) || abilities.can(ACCESS_AD_SERVER, adServer.value.toLowerCase())
  );

export const creativeTracker = {
  namespace: 'creativeTracker',
  state: {
    creatives: [],
    filters: INITIAL_FILTER_STATE,
    assets: {},
    creativeWidths: [],
  },
  reducers: {
    updateCreatives(state, { payload: creatives }) {
      return { ...state, creatives };
    },
    updateCreativeWidths(state, { payload: creativeWidths }) {
      return { ...state, creativeWidths };
    },
    updateCpIdOptions(state, { payload: { cps, abilities } }) {
      const currState = clone(state);
      currState.filters.contentPartnerIdentifiers.options = formatCpOptions(getContentPartners(cps, abilities));
      return currState;
    },
    updateAdServerOptions(state, { payload: abilities }) {
      const currState = clone(state);
      currState.filters.adServers.options = getAdServers(abilities);
      return currState;
    },
    updateFilterSelected(state, { payload: selected }) {
      const currState = clone(state);
      Object.entries(selected).forEach(([field, values]) => {
        currState.filters[field].selected = values;
      });
      return currState;
    },
    upsertAssetDetails(state, { payload: { adMediaFileId, details } }) {
      const currState = clone(state);
      currState.assets[adMediaFileId] = details;
      return currState;
    },
    resetFields(state) {
      const currState = clone(state);
      Object.entries(INITIAL_SELECTED_VALUES).forEach(([field, selected]) => {
        currState.filters[field].selected = selected;
      });
      return currState;
    },
  },
  effects: {
    *pageInit(emptyPayload, { call, put, select }) {
      // load in cp identifiers
      const { permissions: abilities } = yield select(currentUserSelector);
      const cpResponse = yield call(getContentPartnerIdentifiers);
      if (cpResponse?.data) {
        yield put({ type: 'updateCpIdOptions', payload: { cps: cpResponse.data, abilities } });
      }
      yield put({ type: 'updateAdServerOptions', payload: abilities });
      const filterSelected = yield select(filterSelectedSelector);
      const resp = yield call(getCreatives, { ...filterSelected, adUnitIds: filterSelected.adUnitIds });

      if (resp?.data?.creativeIngestions) {
        const creatives = translateCreatives(resp.data.creativeIngestions);
        yield put({ type: 'updateCreatives', payload: creatives });
      }
    },
    *fetchAssetDetails({ payload: creative }, { call, put }) {
      const { adUnitKey, mediafileIdentifier, status, vastType } = creative;
      const transcodingKickedOff = STATUSES[status].code > 1;
      const adMediaFileId = `${adUnitKey}:${mediafileIdentifier}`;
      let details = {};

      if (transcodingKickedOff) {
        const resp = yield call(getAssetDetails, adUnitKey, mediafileIdentifier);
        if (resp?.data) {
          details = { ...resp.data, vastType };
        }
      }

      const [currentStepIndex, currentStatus, timeline] = translateAssetDetailsToTimeline(creative, details);
      details = { ...details, currentStepIndex, currentStatus, timeline };
      yield put({ type: 'upsertAssetDetails', payload: { adMediaFileId, details } });
    },
  },
};

const translateCreatives = (creatives) => {
  return creatives.map((creative) => {
    // the adUnitId from creative tracker is in the following form, but users only care about the rawAdUnitId part
    // ex: fwAd-{rawAdUnitId} or telariapmp:ad:{rawAdUnitId}
    const adUnitKey = creative.adUnitId;
    const rawAdUnitId = retrieveRawAdUnitId(creative);
    return { ...creative, adUnitId: rawAdUnitId, adUnitKey: adUnitKey };
  });
};

const retrieveRawAdUnitId = ({ adServer, adUnitId: adUnitKey }) => {
  switch (adServer) {
    case AD_SERVER.GOOGLE:
    case AD_SERVER.TELARIA_FOR_HULU:
    case AD_SERVER.TELARIA_FOR_PARTNERS:
    case AD_SERVER.ROKU:
    case AD_SERVER.AMAZON: {
      // strip away metadata like i.e. `telariapmp:ad:{id}`, `dfp:innovid:ad:{id}`
      // the reason we don't just want the last thing after the last : is that some telaria ads have : in their ids,
      // which we want to preserve in the UI
      return subString(adUnitKey, /^(dfp|telaria|amazon_tam|roku).*:ad:(.*)/, 2) || adUnitKey;
    }
    case AD_SERVER.FREEWHEEL:
    case AD_SERVER.HULU: {
      // freewheel and hulu ad ids come in the form `fwAd-{id}` and `ad-{id}`, respectively
      // the ids after the `-` should all be integers but in case they're not, I'd prefer to match on the regex below
      // rather than splitting blindly on `-`
      return subString(adUnitKey, /^(ad|fwAd)-(.*)/, 2) || adUnitKey;
    }
    default: {
      return adUnitKey;
    }
  }
};
