import qs from 'query-string';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { notification } from 'antd';
import { Table } from 'ant-table-extensions';
import moment from 'moment-timezone';
import { Resizable } from 'react-resizable';
import {
  AUCTION_SNAPSHOT_DATE_FORMAT,
  AUCTION_SNAPSHOT_US_DATE_FORMAT,
  DEFAULT_MIN_WIDTH,
  DELETE,
  EASTERN_TIME_ZONE,
  PATCH,
  POST,
  PUT,
  SERVER_ERROR,
} from './constants';
import { isUUID } from 'validator';
import { SearchOutlined } from '@ant-design/icons';
import { DatePicker } from 'antd';
import { Moment } from 'moment';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';

export const parseUrl = (path, pathPattern, search) => ({
  pathParams: pathPattern.match(path),
  searchParams: qs.parse(search),
});

export const arrToMap = (arr) =>
  arr.reduce(function (map, obj) {
    map[obj.key] = obj;
    return map;
  }, {});

export const dedupArr = (arr) => [...new Set(arr)];

export const getFromRedisKey = (key, field) => {
  const splitKey = key.split(':');
  const index = splitKey.indexOf(field);
  return index > -1 ? splitKey[index + 1] : null;
};

export const dateRender = (date) => moment(date).format('MM/DD/YYYY h:mm:ss');

export const formatDate = (date) =>
  moment.tz(date, AUCTION_SNAPSHOT_DATE_FORMAT, EASTERN_TIME_ZONE).format(AUCTION_SNAPSHOT_US_DATE_FORMAT);

export const camelCaseToSentenceCase = (str) => {
  const result = str.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const kebabCaseToSentenceCase = (str) =>
  str
    .replace(/-/g, ' ')
    .toLowerCase()
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');

export const jwtTokenHeader = async (extraHeaders) => ({
  headers: {
    Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    ...extraHeaders,
  },
});

export const request = async (
  verb,
  url,
  payload,
  config,
  errMsg,
  useNotification = true, // flag to control error handling behavior
  errorHandler = (err) => {
    if (useNotification) {
      const errorDetail = err.response?.data?.message || err.message || 'An error occurred';
      notification.error({
        message: errMsg,
        description: errorDetail,
        duration: 5,
      });
    }
    throw err; // Re-throw the error for legacy clients
  }
) => {
  let call;
  switch (verb) {
    case POST:
      call = () => axios.post(url, payload, config);
      break;
    case PUT:
      call = () => axios.put(url, payload, config);
      break;
    case PATCH:
      call = () => axios.patch(url, payload, config);
      break;
    case DELETE:
      call = () => axios.delete(url, config);
      break;
    default:
      call = () => axios.get(url, config);
  }
  try {
    return await call();
  } catch (err) {
    return errorHandler(err);
  }
};

export const filterObjByKeys = (srcObj, filterFn) => {
  Object.keys(srcObj)
    .filter(filterFn)
    .reduce((obj, key) => {
      obj[key] = srcObj[key];
      return obj;
    }, {});
};

export const keyDataZipper = (keysArray, dataArray) => {
  if (keysArray.length !== dataArray.length) {
    return [];
  }
  return keysArray.map((item, i) => [item, dataArray[i]]);
};
/**
 * Only columns with prop = 'width' will be resizable.
 * Reference: https://ant.design/components/table/#components-table-demo-resizable-column
 */
export const ResizableHeader = (props) => {
  const { onResize, onRow, width, minWidth, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      minConstraints={[minWidth, 0]}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{
        enableUserSelectHack: false,
      }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

export const ResizableTable = (props) => {
  const handleResize =
    (index) =>
    (_, { size }) => {
      const newWidths = [...props.inputColumns.map((c) => c.width)];
      newWidths[index] = size.width;
      props.widthHandler(newWidths);
    };
  /* eslint-enable */

  const reconcileColumns = props.inputColumns.map((col, index) => ({
    ...col,
    onHeaderCell: (column) => ({
      width: column.width,
      minWidth: column.minWidth ? column.minWidth : DEFAULT_MIN_WIDTH,
      onResize: handleResize(index),
      onRow: column.onRow,
    }),
  }));

  return (
    <Table
      components={{
        header: {
          cell: ResizableHeader,
        },
      }}
      searchableProps={props.searchableProps}
      className={props.className}
      dataSource={props.dataSource}
      columns={reconcileColumns}
      onChange={props.changeHandler}
      scroll={props.scroll}
      pagination={props.pagination}
      data-testid={props.testId}
      rowClassName={props.rowClassName}
      rowSelection={props.rowSelection}
      onRow={props.onRow}
      expandable={props.expandable}
      exportableProps={props.exportableProps}
      refresh={props.refresh}
    />
  );
};

const validateFormValue = (condition) => {
  if (condition) {
    return Promise.resolve();
  } else {
    return Promise.reject('Value failed validation');
  }
};

export const FORM_UUID_RULE = {
  message: 'Must be in UUID format',
  validator: (_, value) => validateFormValue(isUUID(value)),
};

export const NO_EXTRA_SPACE_RULE = {
  message: 'Please remove extra spaces in both ends',
  validator: (_, value) => validateFormValue(value.trim() === value),
};

export const FORM_NOT_EMPTY_RULE = {
  message: 'Value cannot be empty',
  validator: (_, value) => validateFormValue(value.trim() !== ''),
};

export const FORM_HULU_ID_RULE = {
  message: 'Invalid Hulu ID',
  validator: (_, value) => validateFormValue(/^\d+$/.test(value)),
};

export const FORM_NUMBERS_RULE = {
  message: 'Must be in numeric format',
  validator: (_, values) => validateFormValue(values.every((value) => !isNaN(value))),
};

export const getLocalTimezoneAbbreviation = () => moment().tz(moment.tz.guess()).format('z');

// Antd v5 switched to using day.js instead of moment.js. This component allows us to continue using moment.js
export const MomentDatePicker = DatePicker.generatePicker(momentGenerateConfig);
