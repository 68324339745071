import { useMemo } from 'react';
import { VDMS, DSS, CAS } from '../constants';

const DEFAULT_SUPPLY_SOURCE_CONTENT_DETAILS = {
  contentId: '-',
  contentAssetId: '-',
  contentPartnerIdentifier: '-',
  contentGenre: '-',
  contentLen: '-',
  contentLiveStream: '-',
  contentEXTChannel: '-',
  contentSeries: '-',
  distributor: '-',
};

const vdmsSupplySourceContentDetails = (data) => ({
  type: VDMS,
  contentId: data?.rawData?.content?.id,
  contentGenre: data?.rawData?.content?.genre,
  contentLen: data?.rawData?.content?.len,
  contentLiveStream: data?.rawData?.content?.livestream,
  contentEXTChannel: data?.rawData?.content?.ext?.channel,
});

const dssSupplySourceContentDetails = (data) => ({
  type: DSS,
  contentId: data?.rawData?.app?.content?.id,
  contentGenre: data?.rawData?.app?.content?.genre,
  contentLen: data?.rawData?.app?.content?.len,
  contentLiveStream: data?.rawData?.app?.content?.livestream,
  contentEXTChannel: data?.rawData?.app?.content?.ext?.channel,
});

const casSupplySourceContentDetails = (data) => {
  const info = data?.rawData?.['content-info'];
  const partnerId = info?.['content-partner-id'];
  const partnerIdentifier = info?.['content-partner-identifier'];
  return {
    type: CAS,
    contentId: info?.['asset-id'],
    contentPartnerIdentifier: `${partnerId} ${partnerIdentifier}`,
    contentGenre: info?.genres,
    contentSeries: info?.series,
    contentLen: '-',
    contentLiveStream: info?.vdm,
    contentEXTChannel: info?.channel,
    distributor: info?.distributor,
  };
};

const supplySourceMap = {
  [VDMS]: vdmsSupplySourceContentDetails,
  [DSS]: dssSupplySourceContentDetails,
  [CAS]: casSupplySourceContentDetails,
};

const resolveSupplySourceContentDetails = (type, data) => {
  const fn = supplySourceMap[type];
  return fn ? fn(data) : DEFAULT_SUPPLY_SOURCE_CONTENT_DETAILS;
};

export const useSupplySourceContentDetailsResolver = (type, data) => {
  return useMemo(() => resolveSupplySourceContentDetails(type, data), [type, data]);
};
