const REQUIRED_POPULATED_LABEL = '(Required if populated)';

export const FORM_FIELDS = {
  SNAPHOT_NAME: {
    label: 'Snapshot Name',
    name: 'snapshotName',
  },
  USER_PROFILE: {
    label: `Selected ID ${REQUIRED_POPULATED_LABEL}`,
    name: 'userProfile',
  },
  SUPPLY_SOURCE: {
    label: 'Supply Source',
    name: 'supplySource',
  },
  ADVERTISER: {
    label: 'Advertiser (adomain)',
    name: 'advertiser',
  },
  CREATIVE_ID: {
    label: `DSP : CRID ${REQUIRED_POPULATED_LABEL}`,
    name: 'creativeId',
  },
  INPUT_VALUE: {
    label: `Device ID / Profile ID ${REQUIRED_POPULATED_LABEL}`,
    name: ['selectionSearch', 'inputValue'],
  },
  RESULT: {
    label: 'Result',
    name: 'result',
  },
  INVENTORY_TYPE: {
    label: 'Inventory Type',
    name: 'inventoryType',
  },
  APP_NAME: {
    label: 'Publisher',
    name: 'appName',
  },
  DEVICE_FAMILY: {
    label: 'Device Family',
    name: 'deviceFamily',
  },
  IFA_TYPE: {
    label: 'IFA Type',
    name: 'ifaType',
  },
  DOMAIN: {
    label: 'Domain',
    name: 'domain',
  },
  USER_AGENT: {
    label: 'User Agent',
    name: 'userAgent',
  },
  DNT: {
    label: 'DNT',
    name: 'dnt',
  },
  CHANNEL: {
    label: 'Channel',
    name: 'channel',
  },
  SERIES: {
    label: 'Series',
    name: 'series',
  },
  ASSET_ID: {
    label: 'Asset ID/Content ID',
    name: 'assetId',
  },
  CONTENT_PARTNER: {
    label: 'Content Partner',
    name: 'contentPartner',
  },
  VDM: {
    label: 'VDM',
    name: 'vdm',
  },
  CREATIVE_DURATION: {
    label: 'Creative Duration',
    name: 'creativeDuration',
  },
  MEDIA_FILE_URL: {
    label: 'Media File URL',
    name: 'mediaFileUrl',
  },
  WINNING_AUCTION: {
    label: 'Winning Demand Partner',
    name: 'winningAuction',
  },
  AD_PARTNER_DSP: {
    label: 'DSP (Magnite)',
    name: 'adPartnerDsp',
  },
  RECEIVED_BID_DEAL_ID: {
    label: 'Received bid with Specific Deal Type',
    name: 'receivedBidDealId',
  },
  TIME_ALLOWED: {
    label: 'Time Allowed before Stop Running',
    name: 'timeAllowedBeforeStopRunning',
  },
  SESSION_ID: {
    label: `Session ID ${REQUIRED_POPULATED_LABEL}`,
    name: 'sessionId',
  },
  BRAND: {
    label: 'Brand',
    name: 'brand',
  },
  FORM_FACTOR: {
    label: 'Form-Factor',
    name: 'formFactor',
  },
  DEVICE_CATEGORY: {
    label: 'Device Category',
    name: 'deviceCategory',
  },
  DISTRIBUTOR: {
    label: 'Distributor',
    name: 'distributor',
  },
  SELECT_TYPE: {
    label: 'ID Type',
    name: ['selectionSearch', 'selectType'],
  },
  SELECT_ID: {
    label: `Selected ID ${REQUIRED_POPULATED_LABEL}`,
    name: 'selectedId',
  },
  SUPPLY_AD_UNIT_ID: {
    label: 'Supply Ad Unit ID (UUID)',
    name: 'supplyAdUnitID',
  },
  SUPPLY_AD_UNIT_NAME: {
    label: 'Supply Ad Unit Name',
    name: 'supplyAdUnitName',
  },
  BUYER_DEAL_ID: {
    label: 'Deal ID (External)',
    name: 'externalDealId',
  },
  DEAL_NAME: {
    label: 'Deal Name',
    name: 'dealName',
  },
  SYSTEM_DEAL_ID: {
    label: 'System Deal ID (Internal)',
    name: 'internalDealId',
  },
  BUYER_SEAT_ID: {
    label: 'Buyer Seat ID',
    name: 'buyerSeatID',
  },
  PACKAGE_NAME: {
    label: 'Package Name',
    name: 'packageName',
  },
  CREATIVE_NAME: {
    label: 'Creative Name',
    name: 'creativeName',
  },
  BID_STATE: {
    label: 'Bid State',
    name: 'bidState',
  },
  COUNTRY: {
    label: 'Country',
    name: 'country',
  },
};

export const RESULT_OPTIONS = [
  { label: 'All', value: 'all' },
  { label: 'Ads Returned', value: 'ads' },
  { label: 'No Ads Returned', value: 'no_ads' },
];

export const DNT_OPTIONS = [
  { label: 'No Preference', value: 'ALL' },
  { label: 'Enabled', value: 'ENABLED' },
  { label: 'Disabled', value: 'DISABLED' },
];

export const INVENTORY_TYPES = [
  { label: 'All', value: 'all' },
  { label: 'App', value: 'app' },
  { label: 'Web', value: 'web' },
];

export const RECEIVED_BID_DEAL_ID = [
  { label: 'All', value: 'all' },
  { label: 'IOA', value: 'IOA' },
  { label: 'PMP', value: 'PMP' },
];

export const AUCTION_PAYLOAD_NAMES_TO_REMOVE = [
  'name',
  'auctions',
  'id',
  'demandParameters',
  'supplyParameters',
  'date',
  'returnedAuctions',
  'status',
];

export const USER_PARAMETERS_KEY = 'userParameters';
export const APP_PARAMETERS_KEY = 'appParameters';
export const CONTENT_PARAMETERS_KEY = 'contentParameters';
export const DEMAND_PARAMETERS_KEY = 'demandParameters';

export const WINNING_AUCTIONS = {
  amazon: 'amazon',
  roku: 'roku',
  spotx: 'spotX',
  magnite: 'magnite',
  dv360: 'dv360',
  meli: 'meli',
  tradedesk: 'tradedesk',
};

export const INVENTORY_TYPE_MAP = {
  [FORM_FIELDS.INVENTORY_TYPE.name]: 'app',
};

export const FIELD_DISABLED_BY_SUPPLY_SOURCE = {
  [FORM_FIELDS.SESSION_ID.name]: {
    CAS: true,
    DSS: false,
    VDMS: false,
  },
  [FORM_FIELDS.SERIES.name]: {
    CAS: false,
    DSS: true,
    VDMS: true,
  },
  [FORM_FIELDS.ASSET_ID.name]: {
    CAS: false,
    DSS: true,
    VDMS: true,
  },
  [FORM_FIELDS.BRAND.name]: {
    CAS: false,
    DSS: true,
    VDMS: true,
  },
  [FORM_FIELDS.FORM_FACTOR.name]: {
    CAS: false,
    DSS: true,
    VDMS: true,
  },
  [FORM_FIELDS.DEVICE_CATEGORY.name]: {
    CAS: false,
    DSS: true,
    VDMS: true,
  },
  [FORM_FIELDS.COUNTRY.name]: {
    CAS: false,
    DSS: true,
    VDMS: true,
  },
};

export const DISNEY_PLUS = 'DisneyPlus';

export const PARAMETERS_BY_FIELD_NAME = {
  snapshotSearchParametersByAppName: [FORM_FIELDS.APP_NAME.name],
  snapshotSearchParametersByBidState: [FORM_FIELDS.BID_STATE.name],
  snapshotSearchParametersByBrand: [FORM_FIELDS.BRAND.name],
  snapshotSearchParametersByDeviceCategory: [FORM_FIELDS.DEVICE_CATEGORY.name],
  snapshotSearchParametersByChannel: [FORM_FIELDS.CHANNEL.name],
  snapshotSearchParametersByDeviceFamily: [FORM_FIELDS.DEVICE_FAMILY.name],
  snapshotSearchParametersByDistributor: [FORM_FIELDS.DISTRIBUTOR.name],
  snapshotSearchParametersByFormFactor: [FORM_FIELDS.FORM_FACTOR.name],
  snapshotSearchParametersByIfaType: [FORM_FIELDS.IFA_TYPE.name],
  snapshotSearchParametersBySupplySource: [FORM_FIELDS.SUPPLY_SOURCE.name],
  snapshotSearchParametersByVdm: [FORM_FIELDS.VDM.name],
  snapshotSearchParametersByWinningAuction: [FORM_FIELDS.WINNING_AUCTION.name],
  snapshotSearchParametersByCountry: [FORM_FIELDS.COUNTRY.name],
};

export const PARAMETER_NAMES = {
  [FORM_FIELDS.SUPPLY_SOURCE.name]: [FORM_FIELDS.SUPPLY_SOURCE.name],
  [FORM_FIELDS.APP_NAME.name]: [FORM_FIELDS.APP_NAME.name],
  [FORM_FIELDS.DEVICE_FAMILY.name]: [FORM_FIELDS.DEVICE_FAMILY.name],
  [FORM_FIELDS.IFA_TYPE.name]: [FORM_FIELDS.IFA_TYPE.name],
  [FORM_FIELDS.CHANNEL.name]: [FORM_FIELDS.CHANNEL.name],
  [FORM_FIELDS.VDM.name]: [FORM_FIELDS.VDM.name],
  [FORM_FIELDS.WINNING_AUCTION.name]: [FORM_FIELDS.WINNING_AUCTION.name],
  [FORM_FIELDS.DISTRIBUTOR.name]: [FORM_FIELDS.DISTRIBUTOR.name],
  [FORM_FIELDS.BRAND.name]: [FORM_FIELDS.BRAND.name],
  [FORM_FIELDS.DEVICE_CATEGORY.name]: [FORM_FIELDS.DEVICE_CATEGORY.name],
  [FORM_FIELDS.FORM_FACTOR.name]: [FORM_FIELDS.FORM_FACTOR.name],
  [FORM_FIELDS.COUNTRY.name]: [FORM_FIELDS.COUNTRY.name],
};
