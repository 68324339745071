import moment from 'moment';
import { STATUSES, VAST, VPAID } from '../../constants';

// TODO to simplify this entire logic. At the moment this is a mountain of work to do so punting into
// https://jira.hulu.com/browse/VIOLET-1980

export const translateAssetDetailsToTimeline = (creative, asset) => {
  // set up an ID for later reference
  asset.itemKey = creative.itemKey;
  // extract out the current status information
  const status = getStatus(creative, asset);
  // construct status
  asset.computedStatus = {
    name: status.name,
    code: status.code,
    // evaluate status and compile timestamps for each status
    timestamps: computeStatusTimestamps(creative, asset),
  };

  const timeline = buildTimeline(asset.computedStatus);
  const currentStepIndex = getCurrentStep(timeline);
  const currentStep = timeline[currentStepIndex];
  return [currentStepIndex, currentStep.status, timeline];
};

const getStatus = ({ status }, { statusFromCreativeTracker }) => {
  const statusName = statusFromCreativeTracker ? statusFromCreativeTracker : status;
  const statusCode = STATUSES[statusName].code;
  if (!statusCode) {
    return { name: STATUSES.UNKNOWN.title, code: STATUSES.UNKNOWN.code };
  }
  return { name: statusName, code: statusCode };
};

const computeStatusTimestamps = (creativeIngestion, asset) => {
  const statusTimestamps = {};

  // ### ingesting
  if (!asset.createdAt) {
    statusTimestamps.INGESTING = creativeIngestion.lastSeen;
    if (asset.statusFromCreativeTracker === 'SYSTEM_REJECTION') {
      statusTimestamps.SYSTEM_REJECTION = creativeIngestion.lastSeen;
    }
    return statusTimestamps;
  }
  statusTimestamps.INGESTING =
    creativeIngestion.vastType === VPAID ? asset.vpaidQcVast.createdAt : asset.vastAssetUrl.createdAt;

  // ### created
  // NOTE: the asset may be shared by multiple vastAssetUrls
  statusTimestamps.ASSET_CREATED = asset.createdAt;

  // ### pre-qc transcoding
  // VPAID don't have transcoding process
  if (creativeIngestion.vastType === VAST) {
    statusTimestamps.PRE_QC_TRANSCODING = asset.preQcTranscodeJobs
      .map((job) => {
        return job.updatedAt;
      })
      .sort()
      .pop();
    const isPreQcTranscodingComplete = asset.preQcTranscodeJobs.every((job) => {
      return job.statusId === 15;
    });
    if (asset.preQcTranscodeJobs.length === 0 || !isPreQcTranscodingComplete) {
      return statusTimestamps;
    }
  }

  // ### qc-ing
  const currentQcUpdatedAt = asset.qcTasks
    .map((qc) => qc.updatedAt)
    .sort()
    .pop();
  // because qc task is created a lot earlier, and DVP won't react immediately after
  // PRE_QC_TRANSCODING is done.
  statusTimestamps.QC =
    creativeIngestion.vastType === VPAID || moment(currentQcUpdatedAt).isAfter(statusTimestamps.PRE_QC_TRANSCODING)
      ? currentQcUpdatedAt
      : statusTimestamps.PRE_QC_TRANSCODING;
  if (asset.statusFromCreativeTracker === 'DVP_REJECTION') {
    statusTimestamps.DVP_REJECTION = asset.updatedAt;
    return statusTimestamps;
  }

  // ### post-qc transcoding
  // VPAID don't have transcoding process
  if (creativeIngestion.vastType === VAST) {
    statusTimestamps.POST_QC_TRANSCODING = asset.postQcTranscodeJobs
      .map((job) => {
        return job.updatedAt;
      })
      .sort()
      .pop();
    const isPostQcTranscodingComplete = asset.postQcTranscodeJobs.every((job) => {
      return job.statusId === 15;
    });
    if (asset.postQcTranscodeJobs.length === 0 || !isPostQcTranscodingComplete) {
      return statusTimestamps;
    }
  }
  // published
  if (asset.statusFromCreativeTracker === 'PUBLISHED') {
    statusTimestamps.PUBLISHED = asset.updatedAt;
  }

  return statusTimestamps;
};

const getCurrentStep = (timeline) => {
  const lastStepIndex = timeline.length - 1;

  if (timeline[lastStepIndex].status === 'finish') {
    return lastStepIndex;
  }

  const inProgressIndex = timeline.findIndex((step) => {
    return step.status === 'process' || step.status === 'error';
  });

  // when detail is not found
  return inProgressIndex !== -1 ? inProgressIndex : 0;
};

const buildTimeline = ({ timestamps, code, name }) => {
  return Object.entries(STATUSES)
    .filter(([_, { inTimeline }]) => inTimeline)
    .map(([status, { code: stepStatusCode, title }]) => {
      const timestamp = timestamps[status];

      const currentStep = {
        title,
        status: 'wait',
        timestamp: timestamp ? moment(timestamp).format('MMM Do YY, HH:mm') : null,
      };

      if (code === STATUSES.PUBLISHED.code || code > stepStatusCode) {
        currentStep.status = 'finish';
      } else if (code === stepStatusCode) {
        currentStep.status = name.match(/reject/i) ? 'error' : 'process';
      } else if (code === STATUSES.PUBLISHING.code && stepStatusCode === STATUSES.PUBLISHED.code) {
        currentStep.status = 'process';
      }
      return currentStep;
    });
};
