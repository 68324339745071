import React from 'react';
import { RowInfo } from '../Common/RowInfo';
import { StyledCard } from '../style';

export const AppDetailsCard = ({ isLoading, data }) => {
  return (
    <StyledCard title="App Details" loading={isLoading}>
      <RowInfo title="App ID" data={data?.app?.id || data?.content?.['bundle-id']} />
      <RowInfo title="Publisher" data={data?.app?.name} />
      <RowInfo title="App Bundle" data={data?.app?.bundle} />
      <RowInfo title="App Domain" data={data?.app?.domain} />
      <RowInfo title="App Store Url" data={data?.app?.storeurl} />
    </StyledCard>
  );
};
