import { Checkbox, Popover, Select, Tag, Tree } from 'antd';
import moment from 'moment';
import { FilterFilled, InfoCircleOutlined } from '@ant-design/icons';
import {
  createExploreDetails,
  createTableColumnFilters,
  createTableRowToolTip,
  auctionSnapshotActions,
  editableSnapshotNameCell,
  snapshotStatuses,
  StyledAuctionName,
} from './tableUtils';
import {
  ACTIVE,
  CHANGE_TYPE,
  CHANNEL_ID,
  DURATION_MS,
  EXPIRED,
  NA,
  REQUIREMENT_PASS_STATUS,
  SERIES_ID,
  STATUS,
  LEAGUE_ID,
  STATUSES,
  SUSPENDED,
  TAG_VALID_STATUS,
  USER_PROFILE,
  SINGLE_PROGRAM,
  AUCTION_SNAPSHOT_DETAILED_VIEW,
  PARTIAL_POD,
  SPORTS_ID,
  EVENT_ID,
} from '../../constants';
import { dateRender, formatDate, getLocalTimezoneAbbreviation } from '../../utils';
import { ruleStatusRender } from '../DurationManager/utils';
import { idRender } from '../DurationManager/utils';
import {
  RULE_CHANNEL_ID,
  RULE_DURATIONS_MILLISECONDS,
  RULE_LAST_UPDATED,
  RULE_SERIES_ID,
  RULE_LEAGUE_ID,
  RULE_SINGLE_PROGRAM,
  RULE_SINGLE_PROGRAM_DISPLAY,
  RULE_STATUS,
  RULE_TYPE,
  IDS,
  STREAM_TYPE,
  RULE_PARTIAL_POD,
  RULE_PARTIAL_POD_DISPLAY,
  RULE_EVENT_ID,
  RULE_SPORTS_ID,
} from '../DurationManager/constants';
import {
  AD_UNIT_ID_KEY,
  AD_SERVER_KEY,
  AD_SYSTEM_KEY,
  ADVERTISER_KEY,
  CAMPAIGN_MANAGER_KEY,
  LAST_SEEN_KEY,
  MEDIA_FILE_ID_KEY,
  STATUS_KEY,
} from '../CreativeTracker/constants';
import {
  AD,
  AD_ID,
  CREATIVE,
  ERROR_MESSAGE,
  IMPRESSION,
  INLINE,
  LINEAR,
  MEDIA_FILE,
  MEDIA_PASS,
  PARAMETER,
  TYPE,
  VALUE,
  VAST_PASS,
  VAST,
  VPAID,
} from '../QCheck/constants';
import {
  AD_SERVER_TITLE,
  CONTENT_INFO_TITLE,
  CONTENT_DISTRIBUTOR_TITLE,
  COUNTRY_TITLE,
  DEVICE_TITLE,
  DEVICE_DISTRIBUTOR_TITLE,
  INTEGRATION_TITLE,
  POD_TITLE,
  PUBLISHER_TITLE,
  STATUS_TITLE,
  TEST_CASE_CREATED_AT,
  TEST_CASE_ID,
  TEST_CASE_PROFILE_NAME,
  TEST_CASE_STATUS,
  TIME_TITLE,
  TRACE_AD_SERVER,
  TRACE_CONTENT_PARTNER_IDENTIFIER,
  TRACE_CONTENT_TITLE,
  TRACE_COUNTRY,
  TRACE_DEVICE_NAME,
  TRACE_DEVICE_DISTRIBUTOR_NAME,
  TRACE_CONTENT_DISTRIBUTOR_NAME,
  TRACE_INTEGRATION,
  TRACE_POD,
  TRACE_PUBLISHER,
  TRACE_SERIES_ID,
  TRACE_STATUS,
  TRACE_TIME,
  TEST_CASE_ACTION,
} from '../AdsTracer/constants';
import {
  configStatusRender,
  CP_IDENTIFIER,
  HISTORY_ACTION,
  NETWORK_NAME,
  SOURCE_ID,
  TRIGGER_TYPE,
  UPDATED_AT,
  UPDATED_BY,
  UPID_LOOKUP_STRATEGY,
} from '../ConfigManager/utils';

const { Option } = Select;

// TEST CASES SUMMARIES TABLE
const testCaseTableStatusRender = (status, { isSharedToOther, isSharedToMe }) => {
  let testCaseStatus;
  let sharedToOtherStatus;
  let sharedToMeStatus;

  switch (status) {
    case ACTIVE:
      testCaseStatus = <Tag color="green">Active</Tag>;
      break;
    case SUSPENDED:
      testCaseStatus = <Tag color="orange">Suspended</Tag>;
      break;
    case EXPIRED:
      testCaseStatus = <Tag color="red">Expired</Tag>;
      break;
    default:
      testCaseStatus = <Tag color="blue">Unknown</Tag>;
  }

  if (isSharedToOther) {
    sharedToOtherStatus = <Tag color="yellow">Shared</Tag>;
  }

  if (isSharedToMe) {
    sharedToMeStatus = <Tag color="yellow">Shared to me</Tag>;
  }

  return (
    <>
      {testCaseStatus}
      {sharedToOtherStatus}
      {sharedToMeStatus}
    </>
  );
};

const testCaseTableRowRender = (
  val,
  {
    email,
    accountId,
    profileId,
    profileName,
    testCaseId,
    expireAt,
    createdAt,
    subscription,
    isSharedToOther,
    sharedTime,
  }
) => {
  const localTimeZone = getLocalTimezoneAbbreviation();
  return createTableRowToolTip(val, [
    `Test Case Id: ${testCaseId}`,
    ...(email ? [`Email: ${email}`] : []),
    ...(accountId ? [`Account Id: ${accountId}`] : []),
    ...(profileId ? [`Profile Id: ${profileId}`] : []),
    ...(profileName ? [`Profile Name: ${profileName}`] : []),
    `Created At (${localTimeZone}): ${createdAt}`,
    `Expired At (${localTimeZone}): ${expireAt}`,
    ...(subscription ? [`Subscriptions: ${subscription?.packages?.map((pkg) => pkg.value).join(' | ')}`] : []),
    ...(isSharedToOther ? ['*This test case is being shared with others'] : []),
    ...(sharedTime ? [`Test Case shared At (${localTimeZone}): ${sharedTime}`] : []),
  ]);
};

export const testCaseColumns = (testCases, rowExtraRender, customFilter, rangeFilter, widths) => [
  {
    title: 'Profile',
    dataIndex: TEST_CASE_PROFILE_NAME,
    key: TEST_CASE_PROFILE_NAME,
    ellipsis: true,
    render: testCaseTableRowRender,
    filters: createTableColumnFilters(testCases, TEST_CASE_PROFILE_NAME),
    filterSearch: true,
    onFilter: (value, record) => record[TEST_CASE_PROFILE_NAME] === value,
    width: widths[TEST_CASE_PROFILE_NAME] || 100,
  },
  {
    title: 'Test Case',
    dataIndex: TEST_CASE_ID,
    key: TEST_CASE_ID,
    ellipsis: true,
    render: testCaseTableRowRender,
    filterSearch: true,
    filters: createTableColumnFilters(testCases, TEST_CASE_ID),
    onFilter: (value, record) => record[TEST_CASE_ID] === value,
    width: widths[TEST_CASE_ID] || 100,
  },
  {
    title: 'Status',
    dataIndex: TEST_CASE_STATUS,
    key: TEST_CASE_STATUS,
    render: testCaseTableStatusRender,
    filters: createTableColumnFilters(testCases, TEST_CASE_STATUS),
    onFilter: (value, record) => record[TEST_CASE_STATUS] === value,
    width: widths[TEST_CASE_STATUS] || 100,
  },
  {
    title: `Time Created (${getLocalTimezoneAbbreviation()})`,
    dataIndex: TEST_CASE_CREATED_AT,
    key: TEST_CASE_CREATED_AT,
    render: (createdAt) => createdAt,
    width: widths[TEST_CASE_CREATED_AT] || 100,
    filterIcon: <FilterFilled style={{ color: rangeFilter ? '#1890ff' : undefined }} />,
    filterDropdown: customFilter,
  },
  {
    title: 'Actions',
    dataIndex: TEST_CASE_ACTION,
    key: TEST_CASE_ACTION,
    width: widths[TEST_CASE_ACTION] || 100,
    render: rowExtraRender,
  },
];

// TRACE SUMMARIES TABLE
const traceTableRowRender = (
  val,
  {
    trace_id,
    integration,
    contentPartnerIdentifier,
    seriesIdentifier,
    contentTitle,
    deviceId,
    distributorPlatform,
    distributorName,
    pod,
    isVppa,
    isOptOut,
    isCoppa,
  }
) =>
  createTableRowToolTip(val, [
    'Trace Id: ' + trace_id,
    'Integration: ' + integration,
    'Content Partner: ' + contentPartnerIdentifier,
    'Series Name: ' + seriesIdentifier,
    'Content Title: ' + contentTitle,
    'Device Id: ' + deviceId,
    'Distributor Platform: ' + distributorPlatform,
    'Distributor Name: ' + distributorName,
    'Ad Position: ' + pod,
    'Vppa: ' + isVppa?.toString(),
    'Opt Out: ' + isOptOut?.toString(),
    'Coppa: ' + isCoppa?.toString(),
  ]);

const translateStatus = (status) => {
  if (status === '200') {
    return 'FILLED';
  } else if (status === '204') {
    return 'POD MISS';
  } else if (status === '400') {
    return 'BAD REQUEST';
  } else if (status === '500') {
    return 'INTERNAL ERROR';
  } else if (status === '504') {
    return 'TIMEOUT';
  } else {
    return status;
  }
};

const traceTableStatusRender = (status) => {
  let tagColor = 'blue';
  if (status === '200') {
    tagColor = 'green';
  } else if (status === '204') {
    tagColor = 'orange';
  } else if (status.toString().startsWith('5') || status.toString().startsWith('4')) {
    tagColor = 'red';
  }

  return <Tag color={tagColor}>{translateStatus(status)}</Tag>;
};

const createTableStatusFilters = (collection, attr) =>
  [...new Set(collection.map((item) => item[attr]))]
    .filter((item) => item)
    .map((uniqueItem) => ({ text: translateStatus(uniqueItem), value: uniqueItem }));

export const traceColumns = (traces, filters, widths, visibleCols) =>
  [
    {
      title: DEVICE_TITLE,
      dataIndex: TRACE_DEVICE_NAME,
      key: TRACE_DEVICE_NAME,
      width: widths[TRACE_DEVICE_NAME] || 100,
      filteredValue: filters[TRACE_DEVICE_NAME] || null,
      render: traceTableRowRender,
      filters: createTableColumnFilters(traces, TRACE_DEVICE_NAME),
      onFilter: (value, record) => record[TRACE_DEVICE_NAME] === value,
    },
    {
      title: INTEGRATION_TITLE,
      dataIndex: TRACE_INTEGRATION,
      key: TRACE_INTEGRATION,
      width: widths[TRACE_INTEGRATION] || 120,
      filteredValue: filters[TRACE_INTEGRATION] || null,
      render: traceTableRowRender,
      filters: createTableColumnFilters(traces, TRACE_INTEGRATION),
      onFilter: (value, record) => record[TRACE_INTEGRATION] === value,
    },
    {
      title: DEVICE_DISTRIBUTOR_TITLE,
      dataIndex: TRACE_DEVICE_DISTRIBUTOR_NAME,
      key: TRACE_DEVICE_DISTRIBUTOR_NAME,
      width: widths[TRACE_DEVICE_DISTRIBUTOR_NAME] || 120,
      filteredValue: filters[TRACE_DEVICE_DISTRIBUTOR_NAME] || null,
      render: traceTableRowRender,
      filters: createTableColumnFilters(traces, TRACE_DEVICE_DISTRIBUTOR_NAME),
      onFilter: (value, record) => record[TRACE_DEVICE_DISTRIBUTOR_NAME] === value,
    },
    {
      title: CONTENT_INFO_TITLE,
      dataIndex: 'contentInfo',
      key: TRACE_CONTENT_PARTNER_IDENTIFIER,
      width: widths[TRACE_CONTENT_PARTNER_IDENTIFIER] || 500,
      minWidth: 100,
      filteredValue: filters[TRACE_CONTENT_PARTNER_IDENTIFIER] || null,
      render: (text, record) => (
        <>
          <p>
            <b>Content Partner:</b> {record[TRACE_CONTENT_PARTNER_IDENTIFIER]}
          </p>
          <p>
            <b>Series:</b> {record[TRACE_SERIES_ID]}
          </p>
          <p>
            <b>Title:</b> {record[TRACE_CONTENT_TITLE]}
          </p>
        </>
      ),
      filters: createTableColumnFilters(traces, TRACE_CONTENT_PARTNER_IDENTIFIER),
      onFilter: (value, record) => record[TRACE_CONTENT_PARTNER_IDENTIFIER] === value,
    },
    {
      title: CONTENT_DISTRIBUTOR_TITLE,
      dataIndex: TRACE_CONTENT_DISTRIBUTOR_NAME,
      key: TRACE_CONTENT_DISTRIBUTOR_NAME,
      width: widths[TRACE_CONTENT_DISTRIBUTOR_NAME] || 120,
      filteredValue: filters[TRACE_CONTENT_DISTRIBUTOR_NAME] || null,
      ellipsis: true,
      render: traceTableRowRender,
      filters: createTableColumnFilters(traces, TRACE_CONTENT_DISTRIBUTOR_NAME),
      onFilter: (value, record) => record[TRACE_CONTENT_DISTRIBUTOR_NAME] === value,
    },
    {
      title: POD_TITLE,
      dataIndex: TRACE_POD,
      key: TRACE_POD,
      ellipsis: true,
      width: widths[TRACE_POD] || 80,
      filteredValue: filters[TRACE_POD] || null,
      render: traceTableRowRender,
      filters: createTableColumnFilters(traces, TRACE_POD),
      onFilter: (value, record) => record[TRACE_POD] === value,
    },
    {
      title: TIME_TITLE,
      dataIndex: TRACE_TIME,
      key: TRACE_TIME,
      width: widths[TRACE_TIME] || 200,
      ellipsis: true,
      render: traceTableRowRender,
      sorter: (t1, t2) => moment(t1['time']).unix() - moment(t2['time']).unix(),
      defaultSortOrder: 'descend',
    },
    {
      title: STATUS_TITLE,
      dataIndex: TRACE_STATUS,
      key: TRACE_STATUS,
      width: widths[TRACE_STATUS] || 120,
      ellipsis: true,
      filteredValue: filters[TRACE_STATUS] || null,
      render: traceTableStatusRender,
      filters: createTableStatusFilters(traces, TRACE_STATUS),
      onFilter: (value, record) => record[TRACE_STATUS] === value,
    },
    {
      title: COUNTRY_TITLE,
      dataIndex: TRACE_COUNTRY,
      key: TRACE_COUNTRY,
      width: widths[TRACE_COUNTRY] || 120,
      ellipsis: true,
      filteredValue: filters[TRACE_COUNTRY] || null,
      render: traceTableRowRender,
      filters: createTableStatusFilters(traces, TRACE_COUNTRY),
      onFilter: (value, record) => record[TRACE_COUNTRY] === value,
    },
    {
      title: AD_SERVER_TITLE,
      dataIndex: TRACE_AD_SERVER,
      key: TRACE_AD_SERVER,
      width: widths[TRACE_AD_SERVER] || 120,
      ellipsis: true,
      filteredValue: filters[TRACE_AD_SERVER] || null,
      render: traceTableRowRender,
      filters: createTableStatusFilters(traces, TRACE_AD_SERVER),
      onFilter: (value, record) => record[TRACE_AD_SERVER] === value,
    },
    {
      title: PUBLISHER_TITLE,
      dataIndex: TRACE_PUBLISHER,
      key: TRACE_PUBLISHER,
      width: widths[TRACE_PUBLISHER] || 120,
      ellipsis: true,
      filteredValue: filters[TRACE_PUBLISHER] || null,
      render: traceTableRowRender,
      filters: createTableStatusFilters(traces, TRACE_PUBLISHER),
      onFilter: (value, record) => record[TRACE_PUBLISHER] === value,
    },
  ].filter((col) => visibleCols.includes(col.title));

export const newTestCaseColumns = (hideEmail) =>
  [
    {
      title: 'Profile Name',
      dataIndex: 'profileName',
      key: 'profileName',
    },
    {
      title: 'User Email',
      dataIndex: 'email',
      key: 'email',
      hidden: hideEmail,
    },
    {
      title: 'Profile Id',
      dataIndex: 'profileId',
      key: 'profileId',
    },
    {
      title: 'Account Id',
      dataIndex: 'accountId',
      key: 'accountId',
    },
  ].filter((item) => !item.hidden);

// ADS OVERRIDE RULES TABLE
export const overridesColumns = (overrideBy, editRuleRender, removeRuleRender, widths) => [
  ...(overrideBy === USER_PROFILE
    ? [
        {
          title: 'Ad Type',
          dataIndex: 'adType',
          key: 'adType',
          width: widths[0] || 70,
        },
        {
          title: 'User/Account Id',
          dataIndex: 'userId',
          key: 'userId',
          width: widths[1] || 100,
        },
        {
          title: 'Profile Id',
          dataIndex: 'profileId',
          key: 'profileId',
          width: widths[2] || 100,
        },
        {
          title: 'Pod Positions',
          dataIndex: 'podPositions',
          key: 'podPositions',
          width: widths[3] || 250,
          render: (positions) => (Array.isArray(positions) ? <Tree treeData={positions} /> : positions),
        },
        {
          title: 'Expires At',
          dataIndex: 'expireAt',
          key: 'expireAt',
          width: widths[4] || 100,
          render: (expireAt) => moment.unix(expireAt).format('MM/DD/YYYY H:mm:ss'),
        },
        {
          title: 'Edit Rule',
          key: 'editRule',
          width: widths[5] || 80,
          render: editRuleRender,
        },
        {
          title: 'Remove Rule',
          key: 'removeRule',
          width: widths[6] || 80,
          render: removeRuleRender,
        },
      ]
    : [
        {
          title: 'Ad Type',
          dataIndex: 'adType',
          key: 'adType',
          width: widths[0] || 70,
        },
        {
          title: 'Device Id',
          dataIndex: 'deviceId',
          key: 'deviceId',
          width: widths[1] || 210,
        },
        {
          title: 'Pod Positions',
          dataIndex: 'podPositions',
          key: 'podPositions',
          width: widths[2] || 250,
          render: (positions) => (Array.isArray(positions) ? <Tree treeData={positions} /> : positions),
        },
        {
          title: 'Expires At',
          dataIndex: 'expireAt',
          key: 'expireAt',
          width: widths[3] || 100,
          render: (expireAt) => moment.unix(expireAt).format('MM/DD/YYYY H:mm:ss'),
        },
        {
          title: 'Edit Rule',
          key: 'editRule',
          width: widths[4] || 80,
          render: editRuleRender,
        },
        {
          title: 'Remove Rule',
          key: 'removeRule',
          width: widths[5] || 80,
          render: removeRuleRender,
        },
      ]),
];

// ADS DATA TABLE
export const mapAdsData = ([id, data]) => ({
  key: id,
  id,
  name: data.name,
  type: data.type,
  viewabilityVendors: data['viewability-vendor-list'],
  frequencyCaps: data['frequency-cap-list'],
  adTags: data['ad-tag-list'],
  lineItem: data['line-item-id'],
  creative: data['creative-id'],
  updatedAt: data['updated-at'],
  createdAt: data['created-at'],
  status: data.status,
  weight: data.weight,
  review: data.review,
  schedule: data.schedule,
  sequence: data.sequence,
  actualStart: data['actual-start'],
  targetingRule: data['targeting-rule-id'],
  permittedCreativeTypes: data['permitted-creative-type-list'],
});

const adsTableRowRender = (val, data) =>
  createTableRowToolTip(val, [
    'Ad Id: ' + data.id,
    'Ad name: ' + data.name,
    'Type: ' + data.type,
    'Line Item Id ' + data.lineItem,
    'Creative Id ' + data.creative,
    'Actual Start ' + data.actualStart,
    'Schedule ' + data.schedule,
    'Sequence ' + data.sequence,
    'Review ' + data.review,
    'Ad Tags ' + data.adTags,
    'Created At ' + data.createdAt,
    'Updated At ' + data.updatedAt,
    'Viewability Vendors ' + data.viewabilityVendors,
    'Targeting Rule Id: ' + data.targetingRule,
    'Permitted Creative Types: ' + data.permittedCreativeTypes,
  ]);

export const adsColumns = (dataSource, showDetailsModal) => [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
    ellipsis: true,
    render: adsTableRowRender,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    render: adsTableRowRender,
    width: 400,
  },
  {
    title: 'Line Item',
    dataIndex: 'lineItem',
    key: 'lineItem',
    ellipsis: true,
    filters: createTableColumnFilters(dataSource, 'lineItem'),
    onFilter: (value, record) => record.lineItem === value,
    render: adsTableRowRender,
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    ellipsis: true,
    filters: createTableColumnFilters(dataSource, 'type'),
    onFilter: (value, record) => record.type === value,
    render: adsTableRowRender,
  },
  {
    title: 'Creative',
    dataIndex: 'creative',
    key: 'creative',
    ellipsis: true,
    filters: createTableColumnFilters(dataSource, 'creative'),
    onFilter: (value, record) => record.creative === value,
    render: adsTableRowRender,
  },
  {
    title: 'Targeting Rule',
    dataIndex: 'targetingRule',
    key: 'targetingRule',
    ellipsis: true,
    filters: createTableColumnFilters(dataSource, 'targetingRule'),
    onFilter: (value, record) => record.targetingRule === value,
    render: adsTableRowRender,
  },
  {
    title: 'Review',
    dataIndex: 'review',
    key: 'review',
    ellipsis: true,
    render: adsTableRowRender,
  },
  {
    title: 'More Details',
    dataIndex: 'details',
    key: 'details',
    width: 80,
    render: (_, { id }) => createExploreDetails(id, showDetailsModal, 'Show More Details of the ad'),
  },
];

// LINE ITEMS
export const mapLineItemsData = ([id, data]) => ({
  key: id,
  id,
  name: data.name,
  tier: data.tier,
  priority: data.priority,
  product: data['ad-product'],
  costMethod: data['cost-method'],
  unitCost: data['unit-cost'],
  billableThirdParty: data['billable-third-party'],
  campaignId: data['campaign-id'],
  frequencyCaps: data['frequency-cap-list'],
  schedule: data.schedule,
  targetingRule: data['targeting-rule'],
  isFiller: data['is-filler'],
  podBuyout: data['pod-buyout'],
  industryExclusive: data['industry-exclusive'],
  typeProperties: data['type-properties'],
});

const lineItemsTableRowRender = (val, data) =>
  createTableRowToolTip(val, [
    'Line Item Id: ' + data.id,
    'Product: ' + data.product,
    'Cost Method: ' + data.costMethod,
    'Unit Cost: ' + data.unitCost,
    'Campaign Id: ' + data.campaignId,
    'Line Item Id ' + data.lineItem,
    'Is Filler ' + data.isFiller,
    'Type Properties ' + data.typeProperties,
  ]);

export const lineItemsColumns = (dataSource, showDetailsModal) => [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
    ellipsis: true,
    render: lineItemsTableRowRender,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    render: lineItemsTableRowRender,
    width: 300,
  },
  {
    title: 'Product',
    dataIndex: 'product',
    key: 'product',
    ellipsis: true,
    filters: createTableColumnFilters(dataSource, 'product'),
    onFilter: (value, record) => record.product === value,
    render: lineItemsTableRowRender,
  },
  {
    title: 'Cost Method',
    dataIndex: 'costMethod',
    key: 'costMethod',
    ellipsis: true,
    filters: createTableColumnFilters(dataSource, 'costMethod'),
    onFilter: (value, record) => record.costMethod === value,
    render: lineItemsTableRowRender,
  },
  {
    title: 'Unit Cost',
    dataIndex: 'unitCost',
    key: 'unitCost',
    ellipsis: true,
    render: lineItemsTableRowRender,
  },
  {
    title: 'Billable 3rd Party',
    dataIndex: 'billableThirdParty',
    key: 'billableThirdParty',
    ellipsis: true,
    render: lineItemsTableRowRender,
  },
  {
    title: 'Campaign Id',
    dataIndex: 'campaignId',
    key: 'campaignId',
    ellipsis: true,
    render: lineItemsTableRowRender,
  },
  {
    title: 'More Details',
    dataIndex: 'details',
    key: 'details',
    width: 80,
    render: (_, { id }) => createExploreDetails(id, showDetailsModal, 'Show More Details of the line item'),
  },
];

// CAMPAIGNS
export const mapCampaignsData = ([id, data]) => ({
  key: id,
  id,
  name: data.name,
  budget: data.budget,
  adAccountId: data['ad-account-id'],
  marketplace: data.marketplace,
  orderType: data['order-type'],
  origin: data.origin,
  traffickerEmail: data['trafficker-email'],
  nielsenRanges: data['nielsen-range-list'],
  orderOmsList: data['order-oms-link'],
  createdAt: data['created-at'],
  updatedAt: data['updated-at'],
  typeProperties: data['type-properties'],
});

const campaignsTableRowRender = (val, data) =>
  createTableRowToolTip(val, [
    'Campaign Id: ' + data.id,
    'Name: ' + data.name,
    'Budget: ' + data.budget,
    'Ad Account Id: ' + data.adAccountId,
    'Marketplace: ' + data.marketplace,
    'Order Type: ' + data.orderType,
    'Origin: ' + data.origin,
    'Trafficker Email: ' + data.traffickerEmail,
    'Created: ' + moment(data.createdAt),
    'Updated: ' + moment(data.updatedAt),
  ]);

export const campaignsColumns = (dataSource, showDetailsModal) => [
  {
    title: 'Campaign Id',
    dataIndex: 'id',
    key: 'id',
    ellipsis: true,
    render: campaignsTableRowRender,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    render: campaignsTableRowRender,
  },
  {
    title: 'Budget',
    dataIndex: 'budget',
    key: 'budget',
    ellipsis: true,
    render: campaignsTableRowRender,
  },
  {
    title: 'Ad Account',
    dataIndex: 'adAccountId',
    key: 'adAccountId',
    ellipsis: true,
    render: campaignsTableRowRender,
  },
  {
    title: 'Marketplace',
    dataIndex: 'marketplace',
    key: 'marketplace',
    ellipsis: true,
    render: campaignsTableRowRender,
  },
  {
    title: 'Origin',
    dataIndex: 'origin',
    key: 'origin',
    ellipsis: true,
    render: campaignsTableRowRender,
  },
  {
    title: 'Order Type',
    dataIndex: 'orderType',
    key: 'orderType',
    ellipsis: true,
    render: campaignsTableRowRender,
  },
  {
    title: 'Trafficker Email',
    dataIndex: 'traffickerEmail',
    key: 'traffickerEmail',
    ellipsis: true,
    render: campaignsTableRowRender,
  },
  {
    title: 'Created',
    dataIndex: 'createdAt',
    key: 'createdAt',
    ellipsis: true,
    render: campaignsTableRowRender,
  },
  {
    title: 'Updated',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    ellipsis: true,
    render: campaignsTableRowRender,
  },
  {
    title: 'More Details',
    dataIndex: 'details',
    key: 'details',
    width: 80,
    render: (_, { id }) => createExploreDetails(id, showDetailsModal, 'Show More Details of this campaign'),
  },
];

// TARGETING RULES
export const mapTargetingRulesData = ([id, data]) => ({
  key: id,
  ruleId: id,
  adLineItemId: data['ad-id'],
  createdOn: data['created-on'],
  updatedOn: data['updated-on'],
  operation: data.operation,
  javaClass: data['java-class'],
  definition: data.definition,
});

const targetingRulesTableRowRender = (val, data) =>
  createTableRowToolTip(val, [
    'Targeting Id: ' + data.ruleId,
    'Ad Id: ' + data.adLineItemId,
    'Type: ' + data.type,
    'Operation: ' + data.operation,
    'Java: ' + data.javaClass,
    'Definition: ' + data.definition,
    'Created On: ' + data.createdOn,
    'Updated On: ' + data.updatedOn,
  ]);

export const targetingRulesColumns = (dataSource, showDetailsModal) => [
  {
    title: 'Rule Id',
    dataIndex: 'ruleId',
    key: 'ruleId',
    ellipsis: true,
    render: targetingRulesTableRowRender,
  },
  {
    title: 'Ad Id',
    dataIndex: 'adLineItemId',
    key: 'adLineItemId',
    ellipsis: true,
    render: targetingRulesTableRowRender,
  },
  {
    title: 'Operation',
    dataIndex: 'operation',
    key: 'operation',
    ellipsis: true,
    filters: createTableColumnFilters(dataSource, 'operation'),
    onFilter: (value, record) => record.operation === value,
    render: targetingRulesTableRowRender,
  },
  {
    title: 'Java Class',
    dataIndex: 'javaClass',
    key: 'javaClass',
    ellipsis: true,
    render: targetingRulesTableRowRender,
  },
  {
    title: 'Created On',
    dataIndex: 'createdOn',
    key: 'createdOn',
    ellipsis: true,
    render: (createdOn, data) => targetingRulesTableRowRender(moment(createdOn).format('MM/DD/YYYY H:mm:ss'), data),
  },
  {
    title: 'Updated On',
    dataIndex: 'updatedOn',
    key: 'updatedOn',
    ellipsis: true,
    render: (updatedOn, data) => targetingRulesTableRowRender(moment(updatedOn).format('MM/DD/YYYY H:mm:ss'), data),
  },
  {
    title: 'More Details',
    dataIndex: 'details',
    key: 'details',
    width: 80,
    render: (_, { ruleId }) =>
      createExploreDetails(ruleId, showDetailsModal, 'Show More Details of the targeting rule'),
  },
];

// BASE ASSETS
export const mapBaseAssetsData = ([id, data]) => ({
  key: id,
  id,
  name: data.name,
  type: data.type,
  fileSize: data['file-size'],
  rating: data.rating,
  review: data.review,
  comments: data.comments,
  s3Url: data['s3-url'],
  updatedAt: data['updated-at'],
  rejectionReason: data['rejection-reason'],
  typeProperties: data['type-properties'],
  creativeLibraryId: data['creative-library-id'],
});

const baseAssetsTableRowRender = (val, data) =>
  createTableRowToolTip(val, [
    'Asset Id: ' + data.id,
    'Type: ' + data.type,
    'Rating: ' + data.rating,
    'S3 URL: ' + data.s3Url,
    'Comments: ' + data.comments,
  ]);

export const baseAssetsColumns = (dataSource, showDetailsModal) => [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
    ellipsis: true,
    render: baseAssetsTableRowRender,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    render: baseAssetsTableRowRender,
    width: 300,
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    ellipsis: true,
    render: baseAssetsTableRowRender,
  },
  {
    title: 'Rating',
    dataIndex: 'rating',
    key: 'rating',
    ellipsis: true,
    render: baseAssetsTableRowRender,
  },
  {
    title: 'Creative Library Id',
    dataIndex: 'creativeLibraryId',
    key: 'creativeLibraryId',
    ellipsis: true,
    render: baseAssetsTableRowRender,
  },
  {
    title: 'Review',
    dataIndex: 'review',
    key: 'review',
    ellipsis: true,
    render: baseAssetsTableRowRender,
  },
  {
    title: 'File Size',
    dataIndex: 'fileSize',
    key: 'fileSize',
    ellipsis: true,
    render: baseAssetsTableRowRender,
  },
  {
    title: 'Rejection Reason',
    dataIndex: 'rejectionReason',
    key: 'rejectionReason',
    ellipsis: true,
    render: baseAssetsTableRowRender,
  },
  {
    title: 'More Details',
    dataIndex: 'details',
    key: 'details',
    width: 80,
    render: (_, { creativeId }) =>
      createExploreDetails(creativeId, showDetailsModal, 'Show More Details of this asset'),
  },
];

// LIVEPOD DURATION MANAGER
export const durationColumns = (durationRules, widths) => [
  {
    title: 'Stream Types',
    dataIndex: STREAM_TYPE,
    key: STREAM_TYPE,
    filters: createTableColumnFilters(durationRules, STREAM_TYPE),
    onFilter: (value, record) => record[STREAM_TYPE] === value,
    width: widths[0] || 150,
  },
  {
    title: 'Rule Types',
    dataIndex: RULE_TYPE,
    key: RULE_TYPE,
    filters: createTableColumnFilters(durationRules, RULE_TYPE),
    onFilter: (value, record) => record[RULE_TYPE] === value,
    width: widths[0] || 150,
  },
  {
    title: 'IDs',
    dataIndex: [RULE_CHANNEL_ID, RULE_SERIES_ID, RULE_LEAGUE_ID, RULE_EVENT_ID, RULE_SPORTS_ID],
    key: IDS,
    render: idRender,
    width: widths[2] || 400,
  },
  {
    title: 'Duration(s)',
    dataIndex: RULE_DURATIONS_MILLISECONDS,
    key: RULE_DURATIONS_MILLISECONDS,
    width: widths[4] || 200,
  },
  {
    title: 'Partial Pod',
    dataIndex: RULE_PARTIAL_POD,
    key: RULE_PARTIAL_POD,
    filters: [
      {
        text: RULE_PARTIAL_POD_DISPLAY,
        value: RULE_PARTIAL_POD_DISPLAY,
      },
      {
        text: 'NOT ' + RULE_PARTIAL_POD_DISPLAY,
        value: '-',
      },
    ],
    onFilter: (value, record) => record[RULE_PARTIAL_POD] === value,
    width: widths[4] || 160,
  },
  {
    title: 'Single Program',
    dataIndex: RULE_SINGLE_PROGRAM,
    key: RULE_SINGLE_PROGRAM,
    filters: [
      {
        text: RULE_SINGLE_PROGRAM_DISPLAY,
        value: RULE_SINGLE_PROGRAM_DISPLAY,
      },
      {
        text: 'NOT ' + RULE_SINGLE_PROGRAM_DISPLAY,
        value: '-',
      },
    ],
    onFilter: (value, record) => record[RULE_SINGLE_PROGRAM] === value,
    width: widths[5] || 160,
  },
  {
    title: 'Status',
    dataIndex: RULE_STATUS,
    key: RULE_STATUS,
    render: ruleStatusRender,
    filters: createTableColumnFilters(durationRules, RULE_STATUS),
    onFilter: (value, record) => record[RULE_STATUS] === value,
    width: widths[6] || 100,
  },
  {
    title: 'Last Updated',
    dataIndex: RULE_LAST_UPDATED,
    key: RULE_LAST_UPDATED,
    width: widths[7] || 150,
    render: dateRender,
    defaultSortOrder: 'descend',
    sorter: (t1, t2) => moment(t1[RULE_LAST_UPDATED]).unix() - moment(t2[RULE_LAST_UPDATED]).unix(),
    showSorterTooltip: false,
  },
];

// Q-CHECK
const qcTablePassRender = (pass) => (
  <Tag color={REQUIREMENT_PASS_STATUS[pass].color}>{REQUIREMENT_PASS_STATUS[pass].title}</Tag>
);

const vastTableValidRender = (valid) => (
  <Tag color={TAG_VALID_STATUS[valid].color}>{TAG_VALID_STATUS[valid].title}</Tag>
);

// Config Manager
export const configDataCols = (data, widths) => [
  {
    title: 'CP Identifier',
    dataIndex: CP_IDENTIFIER,
    key: CP_IDENTIFIER,
    width: widths[0] || 150,
  },
  {
    title: 'Source Id',
    dataIndex: SOURCE_ID,
    key: SOURCE_ID,
    width: widths[1] || 150,
  },
  {
    title: 'Trigger',
    dataIndex: TRIGGER_TYPE,
    key: TRIGGER_TYPE,
    width: widths[2] || 150,
    filters: createTableColumnFilters(data, TRIGGER_TYPE),
    onFilter: (value, record) => record[TRIGGER_TYPE] === value,
  },
  {
    title: 'UPID Lookup Strategy',
    dataIndex: UPID_LOOKUP_STRATEGY.name,
    key: UPID_LOOKUP_STRATEGY,
    width: widths[3] || 150,
    render: (item) => item[UPID_LOOKUP_STRATEGY],
    filters: createTableColumnFilters(data, UPID_LOOKUP_STRATEGY),
    onFilter: (value, record) => record[UPID_LOOKUP_STRATEGY] === value,
  },
  {
    title: 'Status',
    dataIndex: STATUS,
    key: STATUS,
    width: widths[4] || 100,
    render: configStatusRender,
    filters: createTableColumnFilters(data, STATUS),
    onFilter: (value, record) => record[STATUS] === value,
  },
  {
    title: 'Updated By',
    dataIndex: UPDATED_BY,
    key: UPDATED_BY,
    width: widths[5] || 150,
    filters: createTableColumnFilters(data, UPDATED_BY),
    onFilter: (value, record) => record[UPDATED_BY] === value,
  },
  {
    title: 'Updated At',
    dataIndex: UPDATED_AT,
    key: UPDATED_AT,
    width: widths[5] || 150,
    render: dateRender,
    defaultSortOrder: 'descend',
    sorter: (t1, t2) => moment(t1[UPDATED_AT]).unix() - moment(t2[UPDATED_AT]).unix(),
  },
];

export const configMgrHistoryCols = (data) => [
  {
    title: 'CP Identifier',
    dataIndex: CP_IDENTIFIER,
    key: CP_IDENTIFIER,
    width: 50,
    filters: createTableColumnFilters(data, CP_IDENTIFIER),
    onFilter: (value, record) => record[CP_IDENTIFIER] === value,
  },
  {
    title: 'Source Id',
    dataIndex: SOURCE_ID,
    key: SOURCE_ID,
    width: 100,
  },
  {
    title: 'Trigger',
    dataIndex: TRIGGER_TYPE,
    key: TRIGGER_TYPE,
    width: 50,
    filters: createTableColumnFilters(data, TRIGGER_TYPE),
    onFilter: (value, record) => record[TRIGGER_TYPE] === value,
  },
  {
    title: 'UPID Lookup Strategy',
    dataIndex: UPID_LOOKUP_STRATEGY.name,
    key: UPID_LOOKUP_STRATEGY,
    width: 50,
    render: (item) => item[UPID_LOOKUP_STRATEGY],
    filters: createTableColumnFilters(data, UPID_LOOKUP_STRATEGY),
    onFilter: (value, record) => record[UPID_LOOKUP_STRATEGY] === value,
  },
  {
    title: 'Network',
    dataIndex: UPID_LOOKUP_STRATEGY[NETWORK_NAME],
    key: NETWORK_NAME,
    width: 50,
    render: (item) => item[NETWORK_NAME],
    filters: createTableColumnFilters(data, NETWORK_NAME),
    onFilter: (value, record) => record[NETWORK_NAME] === value,
  },
  {
    title: 'Status',
    dataIndex: STATUS,
    key: STATUS,
    width: 50,
    render: configStatusRender,
    filters: createTableColumnFilters(data, STATUS),
    onFilter: (value, record) => record[STATUS] === value,
  },
  {
    title: 'Action',
    dataIndex: HISTORY_ACTION,
    key: HISTORY_ACTION,
    width: 50,
    filters: createTableColumnFilters(data, HISTORY_ACTION),
    onFilter: (value, record) => record[HISTORY_ACTION] === value,
  },
  {
    title: 'Updated By',
    dataIndex: UPDATED_BY,
    key: UPDATED_BY,
    width: 50,
    filters: createTableColumnFilters(data, UPDATED_BY),
    onFilter: (value, record) => record[UPDATED_BY] === value,
  },
  {
    title: 'Updated At',
    dataIndex: UPDATED_AT,
    key: UPDATED_AT,
    width: 50,
    render: dateRender,
    defaultSortOrder: 'descend',
    sorter: (t1, t2) => moment(t1[UPDATED_AT]).unix() - moment(t2[UPDATED_AT]).unix(),
  },
];

export const mediaRequirementColumns = (requirements) => [
  {
    title: 'Pass',
    dataIndex: MEDIA_PASS,
    key: MEDIA_PASS,
    width: 50,
    render: qcTablePassRender,
  },
  {
    title: 'Parameter',
    dataIndex: PARAMETER,
    key: PARAMETER,
    sorter: (p1, p2) => p1[PARAMETER].localeCompare(p2[PARAMETER]),
    width: 150,
  },
  {
    title: 'Value',
    dataIndex: VALUE,
    key: VALUE,
    width: 150,
  },
  {
    title: 'Type',
    dataIndex: TYPE,
    key: TYPE,
    sorter: (t1, t2) => t1[TYPE].localeCompare(t2[TYPE]),
    width: 100,
  },
  {
    title: 'Error Message',
    dataIndex: ERROR_MESSAGE,
    key: ERROR_MESSAGE,
    width: 150,
  },
];

export const vastRequirementColumns = (requirements) => [
  {
    title: 'Pass',
    dataIndex: VAST_PASS,
    key: VAST_PASS,
    width: 50,
    render: qcTablePassRender,
  },
  {
    title: 'Ad ID',
    dataIndex: AD_ID,
    key: AD_ID,
    width: 150,
  },
  {
    title: 'Vast',
    dataIndex: VAST,
    key: VAST,
    width: 100,
    render: vastTableValidRender,
  },
  {
    title: 'Ad',
    dataIndex: AD,
    key: AD,
    width: 100,
    render: vastTableValidRender,
  },
  {
    title: 'Inline',
    dataIndex: INLINE,
    key: INLINE,
    width: 100,
    render: vastTableValidRender,
  },
  {
    title: 'Impression',
    dataIndex: IMPRESSION,
    key: IMPRESSION,
    width: 100,
    render: vastTableValidRender,
  },
  {
    title: 'Creative',
    dataIndex: CREATIVE,
    key: CREATIVE,
    width: 100,
    render: vastTableValidRender,
  },
  {
    title: 'Linear',
    dataIndex: LINEAR,
    key: LINEAR,
    width: 100,
    render: vastTableValidRender,
  },
  {
    title: 'Media File',
    dataIndex: MEDIA_FILE,
    key: MEDIA_FILE,
    width: 100,
    render: vastTableValidRender,
  },
  {
    title: 'VPAID',
    dataIndex: VPAID,
    key: VPAID,
    width: 100,
    render: vastTableValidRender,
  },
];

export const mapCreativesData = ([id, data]) => ({
  key: id,
  adId: data.adUnitId ?? NA,
  adServer: data.adServer ?? NA,
  mediafileId: data.mediafileIdentifier ?? NA,
  vastUrl: data.vastUrl ?? NA,
  vastType: data.vastType ?? NA,
  adSystem: data.adSystem ?? NA,
  cpId: data.contentPartnerIdentifier ?? NA,
  advertiser: data.advertiser ?? NA,
  status: data.status ?? NA,
  brand: data.brand ?? NA,
  campaignManagerId: data.campaignManagerId ?? NA,
  campaignManagerAccountName: data.campaignManagerAccountName ?? NA,
  createdAt: data.createdAt ?? NA,
  lastSeen: data.lastSeen ?? NA,
  detail: data.detail ?? NA,
});

export const creativeTrackerExportFields = {
  adServer: 'Ad Server',
  adUnitId: 'Ad Unit Id',
  adSystem: 'System',
  mediafileIdentifier: 'Media File',
  advertiser: 'Advertiser',
  campaignManagerAccountName: 'Campaign Manager Account Name',
  lastSeen: 'Last Seen',
  status: 'Status',
  detail: 'Status Details',
};

const creativesTableRowRender = (val, data) =>
  createTableRowToolTip(val, [
    'Ad Server: ' + data.adServer,
    'Ad Unit Id: ' + data.adUnitId,
    'Vast Type: ' + data.vastType,
    'Media File Id: ' + data.mediafileIdentifier,
    'Vast Url: ' + data.vastUrl,
    'Ad System: ' + data.adSystem,
    'Network: ' + data.contentPartnerIdentifier,
    'Advertiser: ' + data.advertiser,
    'Status: ' + data.status,
    'Brand: ' + data.brand,
    'Campaign Manager Id: ' + data.campaignManagerId,
    'Campaign Manager Account Name: ' + data.campaignManagerAccountName,
    'Asset Detail: ' + data.detail,
    'Created At: ' + moment(data.createdAt).format('MM/DD/YYYY HH:mm:ss'),
    'Last Seen: ' + moment(data.lastSeen).format('MM/DD/YYYY HH:mm:ss'),
  ]);

const creativesTableStatusRender = (status, record) => (
  <Popover
    trigger="hover"
    placement="topLeft"
    overlayStyle={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word', maxWidth: '30%' }}
    title="Status Detail"
    content={record.detail ? record.detail : 'N/A'}
  >
    <Tag color={STATUSES[status].color}>{status}</Tag>
  </Popover>
);

export const creativesColumns = (creatives, widths) => [
  {
    title: 'Ad Server',
    dataIndex: AD_SERVER_KEY,
    key: AD_SERVER_KEY,
    render: (val, data) => creativesTableRowRender(val, data),
    filters: createTableColumnFilters(creatives, AD_SERVER_KEY),
    onFilter: (value, record) => record[AD_SERVER_KEY] === value,
    width: widths[0] || 100,
  },
  {
    title: 'Ad Unit Id',
    dataIndex: AD_UNIT_ID_KEY,
    key: AD_UNIT_ID_KEY,
    ellipsis: true,
    render: creativesTableRowRender,
    filters: createTableColumnFilters(creatives, AD_UNIT_ID_KEY),
    onFilter: (value, record) => record[AD_UNIT_ID_KEY] === value,
    width: widths[1] || 100,
  },
  {
    title: 'System',
    dataIndex: AD_SYSTEM_KEY,
    key: AD_SYSTEM_KEY,
    render: creativesTableRowRender,
    filters: createTableColumnFilters(creatives, AD_SYSTEM_KEY),
    onFilter: (value, record) => record[AD_SYSTEM_KEY] === value,
    width: widths[2] || 100,
  },
  {
    title: 'Media File',
    dataIndex: MEDIA_FILE_ID_KEY,
    key: MEDIA_FILE_ID_KEY,
    ellipsis: true,
    render: creativesTableRowRender,
    filters: createTableColumnFilters(creatives, MEDIA_FILE_ID_KEY),
    onFilter: (value, record) => record[MEDIA_FILE_ID_KEY] === value,
    width: widths[3] || 100,
  },
  {
    title: 'Advertiser',
    dataIndex: ADVERTISER_KEY,
    key: ADVERTISER_KEY,
    render: creativesTableRowRender,
    filters: createTableColumnFilters(creatives, ADVERTISER_KEY),
    onFilter: (value, record) => record[ADVERTISER_KEY] === value,
    width: widths[4] || 100,
  },
  {
    title: 'Campaign Manager',
    dataIndex: CAMPAIGN_MANAGER_KEY,
    key: CAMPAIGN_MANAGER_KEY,
    render: creativesTableRowRender,
    filters: createTableColumnFilters(creatives, CAMPAIGN_MANAGER_KEY),
    onFilter: (value, record) => record[CAMPAIGN_MANAGER_KEY] === value,
    width: widths[5] || 100,
  },
  {
    title: 'Last Seen',
    dataIndex: LAST_SEEN_KEY,
    key: LAST_SEEN_KEY,
    sorter: (a, b) => moment(a[LAST_SEEN_KEY]).valueOf() - moment(b[LAST_SEEN_KEY]).valueOf(),
    defaultSortOrder: 'descend',
    render: (value, data) => creativesTableRowRender(moment(value).format('MM/DD/YYYY HH:mm:ss'), data),
    width: widths[6] || 100,
  },
  {
    title: 'Status',
    dataIndex: STATUS_KEY,
    key: STATUS_KEY,
    render: creativesTableStatusRender,
    filters: createTableColumnFilters(creatives, STATUS_KEY),
    onFilter: (value, record) => record[STATUS_KEY] === value,
    align: 'center',
    width: widths[7] || 100,
  },
];

export const bulkUploaddurationRulesColumns = (dataSource, widths) => [
  {
    title: 'Stream Type',
    dataIndex: STREAM_TYPE,
    key: STREAM_TYPE,
    filters: createTableColumnFilters(dataSource, RULE_TYPE),
    onFilter: (value, record) => record[RULE_TYPE] === value,
    width: widths[0] || 150,
  },
  {
    title: 'Rule Type',
    dataIndex: RULE_TYPE,
    key: RULE_TYPE,
    filters: createTableColumnFilters(dataSource, RULE_TYPE),
    onFilter: (value, record) => record[RULE_TYPE] === value,
    width: widths[1] || 150,
  },
  {
    title: 'Channel Id',
    dataIndex: CHANNEL_ID,
    key: CHANNEL_ID,
    filters: createTableColumnFilters(dataSource, CHANNEL_ID),
    onFilter: (value, record) => record[CHANNEL_ID] === value,
    width: widths[2] || 300,
  },
  {
    title: 'Series Id',
    dataIndex: SERIES_ID,
    key: SERIES_ID,
    filters: createTableColumnFilters(dataSource, SERIES_ID),
    onFilter: (value, record) => record[SERIES_ID] === value,
    width: widths[3] || 300,
  },
  {
    title: 'League Id',
    dataIndex: LEAGUE_ID,
    key: LEAGUE_ID,
    filters: createTableColumnFilters(dataSource, LEAGUE_ID),
    onFilter: (value, record) => record[LEAGUE_ID] === value,
    width: widths[4] || 300,
  },
  {
    title: 'Event Id',
    dataIndex: EVENT_ID,
    key: EVENT_ID,
    filters: createTableColumnFilters(dataSource, EVENT_ID),
    onFilter: (value, record) => record[EVENT_ID] === value,
    width: widths[5] || 300,
  },
  {
    title: 'Sports Id',
    dataIndex: SPORTS_ID,
    key: SPORTS_ID,
    filters: createTableColumnFilters(dataSource, SPORTS_ID),
    onFilter: (value, record) => record[SPORTS_ID] === value,
    width: widths[6] || 300,
  },
  {
    title: 'Pod Durations (ms)',
    dataIndex: DURATION_MS,
    key: DURATION_MS,
    render: (durations) => durations.map((duration) => <Tag key={duration}>{duration}</Tag>),
    width: widths[7] || 150,
  },
  {
    title: (
      <Popover
        trigger="hover"
        placement="right"
        overlayStyle={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word', maxWidth: '300px' }}
        title={<h3>Usage</h3>}
        content={'This field is exclusively applicable in CHANNEL rules. For any other rules, it will be disregarded.'}
      >
        <span>Single Program </span>
        <InfoCircleOutlined />
      </Popover>
    ),
    dataIndex: SINGLE_PROGRAM,
    key: SINGLE_PROGRAM,
    render: (val) => (val === undefined ? '' : val.toString()),
    width: widths[8] || 100,
  },
  {
    title: 'Partial Pod',
    dataIndex: PARTIAL_POD,
    key: PARTIAL_POD,
    render: (val) => (val === undefined ? '' : val.toString()),
    width: widths[9] || 100,
  },
  {
    title: 'Change Type',
    dataIndex: CHANGE_TYPE,
    key: CHANGE_TYPE,
    filters: createTableColumnFilters(dataSource, CHANGE_TYPE),
    width: widths[10] || 100,
    onFilter: (value, record) => record[CHANGE_TYPE] === value,
  },
];

export const reservationAuditsColumns = (dataSource, widths) => [
  {
    title: 'Last Updated',
    dataIndex: 'lastUpdated',
    key: 'lastUpdated',
    sorter: (a, b) => moment(a.lastUpdated).unix() - moment(b.lastUpdated).unix(),
    defaultSortOrder: 'descend',
    render: dateRender,
    width: widths[0] || 100,
  },
  {
    title: 'User Name',
    dataIndex: 'username',
    key: 'username',
    filters: createTableColumnFilters(dataSource, 'username'),
    onFilter: (value, record) => String(record.username) === value,
    width: widths[1] || 100,
  },
  {
    title: 'Content Partner',
    dataIndex: 'contentPartnerName',
    key: 'contentPartnerName',
    filters: createTableColumnFilters(dataSource, 'contentPartnerName'),
    onFilter: (value, record) => String(record.contentPartnerName) === value,
    width: widths[2] || 100,
  },
  {
    title: 'Field',
    dataIndex: 'field',
    key: 'field',
    filters: createTableColumnFilters(dataSource, 'field'),
    onFilter: (value, record) => String(record.field) === value,
    width: widths[3] || 100,
  },
  {
    title: 'Old Value',
    dataIndex: 'oldValue',
    key: 'oldValue',
    width: widths[4] || 100,
  },
  {
    title: 'New Value',
    dataIndex: 'newValue',
    key: 'newValue',
    width: widths[5] || 100,
  },
  {
    title: 'Date Created',
    dataIndex: 'dateCreated',
    key: 'dateCreated',
    sorter: (a, b) => moment(a.dateCreated).unix() - moment(b.dateCreated).unix(),
    render: dateRender,
    width: widths[6] || 100,
  },
];

export const reserveSplitsEpisodesColumns = (handleCheckbox, handleSelect) => [
  {
    title: 'Content Partner Name',
    dataIndex: 'cpName',
    key: 'cpName',
    width: 50,
  },
  {
    title: 'Series Name',
    dataIndex: 'seriesIdentifierName',
    key: 'seriesIdentifierName',
    width: 50,
  },
  {
    title: 'Select All Episodes',
    dataIndex: 'checkbox',
    key: 'checkbox',
    width: 50,
    // eslint-disable-next-line react/display-name
    render: (_) => (
      <Checkbox index={_.series} series={_.series} onChange={handleCheckbox}>
        Use All Episodes
      </Checkbox>
    ),
  },
  {
    title: 'Specify Episodes',
    key: 'select',
    dataIndex: 'select',
    width: 200,
    // eslint-disable-next-line react/display-name
    render: (_) => (
      <Select
        mode="multiple"
        placeholder="Select episodes"
        defaultValue={_.selectedEpisodes}
        style={{ width: '100%' }}
        onChange={handleSelect}
        key={_.index}
        disabled={_.isSelectedAll}
      >
        {_.allEpisodes.map((episode) => (
          <Option key={episode} value={episode} series={_.series}>
            {episode}
          </Option>
        ))}
      </Select>
    ),
  },
];

// AUCTION SNAPSHOT
export const adRequestSnapshotColumns = (adRequestSnapshotActions, sortOrder) => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'snapshotName',
    width: '27%',
    sorter: true,
    sortDirections: ['ascend', 'descend', 'ascend'],
    sortOrder: sortOrder['snapshotName'],
    ellipsis: true,
    render: (text, record) => editableSnapshotNameCell(text, record, adRequestSnapshotActions),
  },
  {
    title: 'Date',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'],
    sortOrder: sortOrder['date'],
    dataIndex: 'date',
    key: 'date',
    width: '20%',
    render: formatDate,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: '10%',
    render: (text) => snapshotStatuses(text),
  },
  {
    title: 'Created By',
    dataIndex: 'createdBy',
    key: 'createdBy',
    width: '13%',
    render: (data) => data && `${data.firstName} ${data.secondName}`,
  },
  {
    title: 'Returned Auctions',
    dataIndex: 'returnedAuctions',
    key: 'returnedAuctions',
    width: '11%',
  },
  {
    title: '',
    dataIndex: 'controlBtn',
    key: 'controlBtn',
    width: '15%',
    render: (text, record) => auctionSnapshotActions(adRequestSnapshotActions, record),
  },
];

export const auctionDetailsColumns = [
  {
    title: '',
    key: 'index',
    render: (text, record, index) => index + 1,
  },
  {
    title: 'Name',
    dataIndex: 'externalAuctionId',
    key: 'externalAuctionId',
    width: '27%',
    render: (text, record) => (
      <StyledAuctionName
        href={`${AUCTION_SNAPSHOT_DETAILED_VIEW}/${record.id}`}
        title="External Auction ID"
        rel="noreferrer"
      >
        {text}
      </StyledAuctionName>
    ),
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    width: '22%',
    render: formatDate,
  },
  {
    title: 'Attempted',
    dataIndex: 'attempted',
    key: 'attempted',
    width: '13%',
  },
  {
    title: 'Returned',
    dataIndex: 'returned',
    key: 'returned',
    width: '12%',
  },
  {
    title: 'Publisher',
    dataIndex: 'appName',
    key: 'appName',
    width: '11%',
  },
  {
    title: 'Device Family',
    dataIndex: 'deviceFamily',
    key: 'deviceFamily',
    width: '11%',
  },
];

export const vastTrackerColumns = (columns, widths) =>
  columns.map((column, index) => ({ ...column, width: index === 0 ? widths[0] || 300 : widths[index] || 125 }));
