import 'react-html5video/dist/styles.css';
import { Col, Row, Card, Statistic, Button, Tabs, Modal } from 'antd';
import { PlayCircleOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { DefaultPlayer as Video } from 'react-html5video';

import { ACCESS, MP4, VIDEO_CONTROLS, VIEW_ASSET_VIDEOS } from '../../../constants';
import {
  CREATIVETRACKER_TABLE_ASSET_MODAL,
  CREATIVETRACKER_TABLE_ASSET_PREVIEW_BUTTON,
  CREATIVETRACKER_TABLE_ASSET_REASON,
  PERMISSION_PLACEHOLDER,
} from '../../../testUtils';
import { connect } from 'dva';

const { TabPane } = Tabs;

const STATISTIC_FONT = { fontSize: '12px' };

const AssetDetailBox = ({ asset = {}, permissions = PERMISSION_PLACEHOLDER }) => {
  // Variables to pass into components
  const { id, isCalabashed, comments, isUrl, isRemote, path } = asset;

  let videoPlayer;

  const statistics = [
    { name: 'Asset ID', value: id ?? 'N/A' },
    { name: 'Asset Name', value: asset?.displayName ?? 'N/A', span: 7 },
    { name: 'Type', value: asset?.vastType ?? 'N/A' },
    { name: 'Length', value: asset?.length ?? 'N/A' },
    { name: 'Height', value: asset?.actualHeight ?? 'N/A' },
    { name: 'Width', value: asset?.actualWidth ?? 'N/A' },
    { name: 'Calabashed', value: isCalabashed ?? 'N/A' },
    { name: 'Pending Agency Approval', value: asset?.pendingAgencyApproval ?? 'N/A' },
  ].map(({ name, value, span = 2 }) => (
    <Col style={{ overflow: 'hidden' }} span={span}>
      <Statistic title={name} value={value} valueStyle={STATISTIC_FONT} groupSeparator={''} />
    </Col>
  ));

  const [setModalOpen, setSetModalOpen] = useState(false);
  const previewButtonOnClick = () => setSetModalOpen(true);
  const modalClose = () => {
    setSetModalOpen(false);
    videoPlayer.videoEl.pause();
  };

  const previewButton =
    // Determine if there's even an asset to preview, to proceed preview rendering
    !isUrl && (isRemote || isCalabashed) ? (
      <div data-testid={CREATIVETRACKER_TABLE_ASSET_PREVIEW_BUTTON}>
        <Col>
          <Button
            type="primary"
            onClick={previewButtonOnClick}
            disabled={permissions.cannot(ACCESS, VIEW_ASSET_VIDEOS)}
          >
            <b>Preview </b>
            <PlayCircleOutlined />
          </Button>
        </Col>
      </div>
    ) : (
      <></>
    );

  const reason =
    comments && comments.length > 0 ? (
      <div data-testid={CREATIVETRACKER_TABLE_ASSET_REASON}>
        <Row>
          <span>
            <b>Reason: </b>
            {comments[0]?.comment}
          </span>
        </Row>
      </div>
    ) : (
      <></>
    );

  const videoSrc = isRemote ? `/api/remote-asset/${path}` : `/api/asset/${encodeURIComponent(path)}`;

  const modal = (
    <Modal
      title={`Video Preview for Asset Id: ${id}`}
      footer={null}
      closable
      open={setModalOpen}
      onCancel={modalClose}
      width="70%"
    >
      <div data-testid={CREATIVETRACKER_TABLE_ASSET_MODAL}>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Original" key="1">
            <Video ref={(el) => (videoPlayer = el)} controls={VIDEO_CONTROLS}>
              <source src={videoSrc} type={MP4} />
            </Video>
          </TabPane>
        </Tabs>
      </div>
    </Modal>
  );

  return (
    <>
      <Card>
        <Row>
          {statistics}
          {previewButton}
        </Row>
        <Row>{reason}</Row>
      </Card>
      {modal}
    </>
  );
};

function mapStateToProps({ app }) {
  return { permissions: app.permissions };
}

export default connect(mapStateToProps)(AssetDetailBox);
