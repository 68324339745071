import React from 'react';
import { RowInfo } from '../Common/RowInfo';
import { StyledCard } from '../style';

export const UserInfoCard = ({ isLoading, data }) => {
  return (
    <StyledCard title="User Info" loading={isLoading}>
      <RowInfo title="User ID" data={data?.['user-info']?.['user-id']} />
      <RowInfo title="User Profile ID" data={data?.['user-info']?.['profile-id']} />
      <RowInfo title="User Age" data={data?.['user-info']?.age} />
      <RowInfo title="User Gender" data={data?.['user-info']?.gender} />
      <RowInfo title="User Package ID" data={data?.['user-info']?.['package-id']} />
      <RowInfo title="User Product ID" data={data?.['user-info']?.['product-id']} />
    </StyledCard>
  );
};
