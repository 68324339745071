import React from 'react';
import { Input, Form, Select } from 'antd';

import { useProfileForm } from './useProfileForm';
import {
  StyledProfileContent,
  StyledProfileInputs,
  StyledProfileActions,
  StyledProfileInput,
  StyledOptionLabel,
  StyledProfileNameInput,
} from './style';
import { HuluButtonCapitalize, HuluNormalButtonCapitalize } from '../AuctionSnapshot';
import { ID_TYPES, INPUT_VALUE_MAX_LENGTH, PROFILE_NAME_MAX_LENGTH } from '../constants';
import { FORM_FIELDS } from './constants';

const { Option } = Select;
const { TextArea } = Input;

export const ProfileForm = ({ onClose, pagination, setLoading, setProfilesState, selectedProfile }) => {
  const { form, selectType, closeForm, handleSubmitFailed, handleSubmit } = useProfileForm({
    pagination,
    onClose,
    setLoading,
    setProfilesState,
    selectedProfile,
  });
  const onCancel = () => {
    closeForm();
  };

  return (
    <Form
      id="profileForm"
      name="profileForm"
      onFinish={handleSubmit}
      onFinishFailed={handleSubmitFailed}
      layout="vertical"
      form={form}
    >
      <StyledProfileContent>
        <div>
          <StyledProfileInputs>
            <Form.Item
              label={FORM_FIELDS.PROFILE_NAME.label}
              name={FORM_FIELDS.PROFILE_NAME.name}
              rules={[{ required: true, message: 'Profile Name is required!' }]}
            >
              <StyledProfileNameInput maxLength={PROFILE_NAME_MAX_LENGTH} showCount allowClear />
            </Form.Item>
          </StyledProfileInputs>
          <StyledProfileInput>
            <Form.Item
              label={FORM_FIELDS.SELECT_TYPE.label}
              name={FORM_FIELDS.SELECT_TYPE.name}
              rules={[{ required: true, message: `${FORM_FIELDS.SELECT_TYPE.label} is required!` }]}
            >
              <Select
                allowClear
                filterOption={false}
                placeholder="Select Type"
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                {ID_TYPES.map((option) => (
                  <Option key={option.value} value={option.value}>
                    <StyledOptionLabel>{option.label}</StyledOptionLabel>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </StyledProfileInput>
          <StyledProfileInput>
            <Form.Item
              label={FORM_FIELDS.INPUT_VALUE.label}
              name={FORM_FIELDS.INPUT_VALUE.name}
              rules={[{ required: true, message: `${FORM_FIELDS.INPUT_VALUE.label} is required!` }]}
            >
              <TextArea
                autoSize={{ minRows: 1, maxRows: 1 }}
                showCount
                maxLength={INPUT_VALUE_MAX_LENGTH}
                disabled={!selectType}
                allowClear
              />
            </Form.Item>
          </StyledProfileInput>
        </div>
        <StyledProfileActions>
          <Form.Item>
            <HuluButtonCapitalize size="medium" variant="outlined" onClick={onCancel}>
              Cancel
            </HuluButtonCapitalize>
          </Form.Item>
          <Form.Item>
            <HuluNormalButtonCapitalize size="medium" variant="normal" htmlType="submit">
              {selectedProfile ? 'Update' : 'Save'} Profile
            </HuluNormalButtonCapitalize>
          </Form.Item>
        </StyledProfileActions>
      </StyledProfileContent>
    </Form>
  );
};
