import { Button, Tooltip, Tag } from 'antd';
import PropTypes from 'prop-types';
import { SearchOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { colors } from '@hulu/design-tokens';
import { IconAlertInfoOutlined } from '@hulu-react-style-components/icons';

import { RerunIcon } from '../AuctionSnapshot/icons/RerunIcon';
import { DeleteIcon } from '../AuctionSnapshot/icons/DeleteIcon';
import { DataActivityIcon } from '../AuctionSnapshot/icons/DataActivityIcon';
import { SNAPSHOT_NAME_MAX_LENGTH } from '../AuctionSnapshot/constants';
import { EditableCell, StyledSnapshotName } from '../AuctionSnapshot/AuctionSnapshotTable/EditableCell';

const CAPTURING = 'CAPTURING';
const TIMEOUT = 'TIMEOUT';

const timeoutBorderColor = '#f5daa3';
const timeoutColor = '#5c410a';
const timeoutBackgroud = '#fcf5e8';

const tooltipOverlayInnerStyle = { borderRadius: '4px', backgroundColor: colors.colorGray82 };

const StyledAuctionSnapshotActionsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`;

const StyledIconButton = styled.span`
  display: flex;
  align-items: center;
  cursor: pointer;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : '')};
`;

export const StyledAuctionName = styled.a`
  color: ${colors.colorBlue50};
  padding: 16px;
`;

const StyledStatusTags = styled(Tag)`
  font-size: 14px;
  min-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  margin: 0;
`;

const StyledStatusTimeoutTags = styled(StyledStatusTags)`
  border-color: ${timeoutBorderColor};
  color: ${timeoutColor};
  background-color: ${timeoutBackgroud};
`;

export const createTableRowToolTip = (val, entries, width = '400px') => {
  const tooltipMsg = (
    <>
      {entries.map((t, i) => (
        <div key={i}>
          {t}
          <br />
        </div>
      ))}
    </>
  );
  return (
    <Tooltip placement="topLeft" title={tooltipMsg} overlayStyle={{ minWidth: width }}>
      {val}
    </Tooltip>
  );
};

export const createTableColumnFilters = (collection, attr) => {
  if (collection === null || attr === null) {
    return [];
  }
  return [...new Set(collection.map((item) => item[attr]))]
    .filter((item) => item)
    .map((uniqueItem) => ({ text: uniqueItem, value: uniqueItem }));
};
export const createExploreDetails = (id, openDetailsModal, tooltipTitle) => (
  <Tooltip title={tooltipTitle}>
    <Button shape="circle" icon={<SearchOutlined />} onClick={() => openDetailsModal(id)} />
  </Tooltip>
);

export const snapshotStatuses = (status) => {
  const statuses = { CAPTURING: 'processing', COMPLETED: 'success', TIMEOUT: 'warning' };
  const color = statuses[status];
  if (status === TIMEOUT) {
    return <StyledStatusTimeoutTags color={color}>{status}</StyledStatusTimeoutTags>;
  }
  return <StyledStatusTags color={color}>{status}</StyledStatusTags>;
};

export const auctionSnapshotActions = (adRequestSnapshotActions, snapshot) => {
  const {
    onSnapshotParametersInfoClick,
    onRerunSnapshotClick,
    onAdTagsParameterSummaryClick,
    onDeleteSnapshotClick,
    currentUserEmail,
  } = adRequestSnapshotActions;
  const isSnapshotSummaryUnavailable = snapshot?.status === CAPTURING || snapshot?.status === TIMEOUT;
  const disabled = snapshot?.createdBy?.email.toLowerCase() !== currentUserEmail.toLowerCase();
  return (
    <StyledAuctionSnapshotActionsWrapper>
      <Tooltip placement="bottom" title="Parameters Applied" overlayInnerStyle={tooltipOverlayInnerStyle}>
        <StyledIconButton>
          <IconAlertInfoOutlined onClick={() => onSnapshotParametersInfoClick(snapshot)} />
        </StyledIconButton>
      </Tooltip>
      <Tooltip placement="bottom" title="Snapshot Summary" overlayInnerStyle={tooltipOverlayInnerStyle}>
        <StyledIconButton disabled={isSnapshotSummaryUnavailable}>
          <DataActivityIcon
            onClick={() => onAdTagsParameterSummaryClick(snapshot)}
            disabled={isSnapshotSummaryUnavailable}
          />
        </StyledIconButton>
      </Tooltip>
      <Tooltip placement="bottom" title="Rerun Snapshot" overlayInnerStyle={tooltipOverlayInnerStyle}>
        <StyledIconButton>
          <RerunIcon onClick={() => onRerunSnapshotClick(snapshot)} />
        </StyledIconButton>
      </Tooltip>
      <Tooltip placement="bottom" title="Delete Snapshot" overlayInnerStyle={tooltipOverlayInnerStyle}>
        <StyledIconButton disabled={disabled}>
          <DeleteIcon onClick={() => onDeleteSnapshotClick(snapshot)} disabled={disabled} />
        </StyledIconButton>
      </Tooltip>
    </StyledAuctionSnapshotActionsWrapper>
  );
};

export const editableSnapshotNameCell = (text, record, adRequestSnapshotActions) => {
  const { currentUserEmail, handleInlineEditName } = adRequestSnapshotActions;
  return record?.createdBy?.email.toLowerCase() === currentUserEmail.toLowerCase() ? (
    <EditableCell
      name={text}
      maxLength={SNAPSHOT_NAME_MAX_LENGTH}
      onSave={(name) => handleInlineEditName(record?.id, name, record?.name)}
    />
  ) : (
    <StyledSnapshotName name={text} />
  );
};

auctionSnapshotActions.propTypes = {
  adRequestSnapshotActions: PropTypes.shape({
    onSnapshotParametersInfoClick: PropTypes.func.isRequired,
    onAdTagsParameterSummaryClick: PropTypes.func.isRequired,
    handleInlineEditName: PropTypes.func.isRequired,
  }),
};
