import {
  AD_INVESTIGATOR_API,
  ADDITIONAL_AD_INVESTIGATOR_API,
  DELETE,
  GET,
  PUT,
  USER_PROFILE,
  VIDEO_AD_TYPE,
} from '../constants';
import { jwtTokenHeader, request } from '../utils';
import { notification } from 'antd';
import { Buffer } from 'buffer';

export const createUpdateOverrides = async (overrideByInfo) => {
  let promises = [];
  for (const host of [AD_INVESTIGATOR_API, ADDITIONAL_AD_INVESTIGATOR_API]) {
    promises.push(
      request(
        PUT,
        determinePathByAdTypeAndOverrideBy(host, overrideByInfo),
        overrideByInfo.rules,
        await jwtTokenHeader(),
        'Failed creating or updating Override Rule.'
      )
    );
  }
  return Promise.all(promises);
};

let validateStatus = (status) => {
  let acceptedStatuses = ['200', '404'];
  return acceptedStatuses.includes(status.toString());
};

export const getOverrides = async (overrideByInfo, adType = VIDEO_AD_TYPE) => {
  const path = determinePathByAdTypeAndOverrideBy(AD_INVESTIGATOR_API, { adType: adType, ...overrideByInfo });
  return request(
    GET,
    path,
    '',
    { ...(await jwtTokenHeader()), ...{ validateStatus: validateStatus } },
    'Failed fetching Override Rules.',
    (err) => {
      if (err?.response?.status === 404) {
        return {};
      } else {
        notification.error({
          message: 'Error getting Overrides',
          description: err.message,
          duration: 5,
        });
        throw err;
      }
    }
  );
};

export const removeOverrides = async (overrideByInfo) => {
  let promises = [];
  for (const host of [AD_INVESTIGATOR_API, ADDITIONAL_AD_INVESTIGATOR_API]) {
    promises.push(
      request(
        DELETE,
        determinePathByAdTypeAndOverrideBy(host, overrideByInfo),
        {},
        await jwtTokenHeader(),
        'Failed deleting Override Rule.'
      )
    );
  }
  return Promise.all(promises);
};

const determinePathByAdTypeAndOverrideBy = (host, { adType, overrideBy, userId, profileId, deviceId }) => {
  const isLookupSpecificRules = userId || deviceId;
  if (!adType) {
    // backward compatible
    adType = VIDEO_AD_TYPE;
  }

  let path;
  if (overrideBy === USER_PROFILE) {
    if (adType === VIDEO_AD_TYPE) {
      path = isLookupSpecificRules ? `users/${userId}/profiles/${profileId}` : 'users';
    } else {
      path = isLookupSpecificRules ? `accounts/${userId}/profiles/${profileId}` : 'accounts';
    }
  } else {
    path = isLookupSpecificRules ? `devices/${Buffer.from(deviceId).toString('base64')}` : 'devices';
  }
  console.log(path);

  return `${host}/v1/ad-overrides/` + (adType === VIDEO_AD_TYPE ? 'in-stream/' : 'pause/') + `${path}`;
};
