import { useMemo } from 'react';
import { VDMS, DSS, CAS } from '../constants';

const DEFAULT_SUPPLY_SOURCE_DEVICE_DETAILS = {
  deviceUA: '-',
  deviceDNT: '-',
  deviceLMT: '-',
  deviceIP: '-',
  deviceIPV6: '-',
  deviceDeviceType: '-',
  deviceMake: '-',
  deviceModel: '-',
  deviceOS: '-',
  deviceOSV: '-',
  deviceHeight: '-',
  deviceWidth: '-',
  deviceIFA: '-',
  deviceIFAType: '-',
  deviceComputerGUID: '-',
  deviceID: '-',
  deviceDistributionPartner: '-',
  deviceDistributorPlatform: '-',
  deviceSessionID: '-',
};

const vdmsSupplySourceDeviceDetails = (data) => ({
  type: VDMS,
  deviceUA: data?.ua,
  deviceDNT: data?.dnt,
  deviceLMT: data?.lmt,
  deviceIP: data?.ip,
  deviceIPV6: data?.ipv6,
  deviceDeviceType: data?.devicetype,
  deviceMake: data?.make,
  deviceModel: data?.model,
  deviceOS: data?.os,
  deviceOSV: data?.osv,
  deviceHeight: data?.h,
  deviceWidth: data?.w,
  deviceIFA: data?.ifa,
  deviceIFAType: data?.ifatype,
  deviceSessionID: data?.sessionid,
});

const dssSupplySourceDeviceDetails = (data) => ({
  type: DSS,
  deviceUA: data?.ua,
  deviceDNT: data?.dnt,
  deviceIP: data?.ip,
  deviceIPV6: data?.ipv6,
  deviceDeviceType: data?.devicetype,
  deviceMake: data?.make,
  deviceModel: data?.model,
  deviceOS: data?.os,
  deviceOSV: data?.osv,
  deviceHeight: data?.h,
  deviceWidth: data?.w,
  deviceIFA: data?.ifa,
  deviceIFAType: data?.ifatype,
  deviceSessionID: data?.sessionid,
  deviceLMT: data?.lmt,
});

const casSupplySourceDeviceDetails = (data) => ({
  type: CAS,
  deviceComputerGUID: data?.['computer-guid'],
  deviceDeviceType: data?.device,
  deviceIFA: data?.['device-ad-id'],
  deviceDNT: data?.['device-dnt'].toString(),
  deviceID: data?.['device-id'],
  devicePartner: data?.['distribution-partner'],
  devicePlatform: data?.['distributor-platform'],
  deviceIP: data?.['ip-address'],
  deviceUA: data?.['user-agent'],
  deviceLMT: data?.lmt,
});

const supplySourceMap = {
  [VDMS]: vdmsSupplySourceDeviceDetails,
  [DSS]: dssSupplySourceDeviceDetails,
  [CAS]: casSupplySourceDeviceDetails,
};

const resolveSupplySourceDeviceDetails = (type, data) => {
  const fn = supplySourceMap[type];
  return fn ? fn(data) : DEFAULT_SUPPLY_SOURCE_DEVICE_DETAILS;
};

export const useSupplySourceDeviceDetailsResolver = (type, data) => {
  const dataDetail = type !== CAS ? data?.device : data?.['device-info'];
  return useMemo(() => resolveSupplySourceDeviceDetails(type, dataDetail), [type, dataDetail]);
};
