import { ASSET_MANAGER_API, GET, TARGETING_PROVIDER_API } from '../constants';
import { jwtTokenHeader, request } from '../utils';

// TODO undo comment in https://jira.disneystreaming.com/browse/VIOLET-3082
// export const getAdCampaignData = async (objectName) =>
//   request(
//     POST,
//     `${ADS_CAMPAIGN_SEARCHER_API}/v2/search/${objectName}`,
//     {},
//     await jwtTokenHeader(),
//     'Fetching Ad/Campaign Data failed.'
//   );

export const getAssetsData = async () =>
  request(GET, `${ASSET_MANAGER_API}`, {}, await jwtTokenHeader(), 'Fetching Assets Data failed.');

export const getTargetingRulesData = async () =>
  request(GET, `${TARGETING_PROVIDER_API}`, {}, await jwtTokenHeader(), 'Fetching Targeting Rules Data failed.');
