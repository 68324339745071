import { GET, PAGINATION_SIZE, POST } from '../constants';
import { jwtTokenHeader, request } from '../utils';

export const getAssetDetails = async (adId, mediaFileId) =>
  request(
    GET,
    `/api/creative-ingests/${encodeURIComponent(adId)}/${encodeURIComponent(mediaFileId)}/asset-details`,
    {},
    await jwtTokenHeader(),
    'Creative Ingest Details load failed.'
  ).catch((err) => {
    console.error('Failed to get asset details! ' + err);
  });

export const getCreatives = async (filterSelected) =>
  request(
    POST,
    `/api/creative-ingests/search`,
    { ...filterSelected, paginationSize: PAGINATION_SIZE },
    await jwtTokenHeader(),
    'Fetching Creatives failed.'
  ).catch((err) => {
    console.error('Failed to get creatives! ' + err);
  });

export const getContentPartnerIdentifiers = async () =>
  request(
    GET,
    `/api/content-partner-provider/metadata/content-partners`,
    {},
    await jwtTokenHeader(),
    'Failed to load content partner identifiers.'
  ).catch((err) => {
    console.error('Failed to get content partner identifiers! ' + err);
  });
