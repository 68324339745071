import { useMemo } from 'react';
import { CAS, VDMS, DSS, CREATED_TIMESTAMP, REQUEST_TYPE_MAPPING } from '../constants';
import { timestampIntegrators } from '../timestampIntegrators';

const DEFAULT_SUPPLY_SOURCE_SUMMARY = {
  type: '-',
  appName: '-',
  channel: '-',
  deviceFamily: '-',
  vdm: '-',
  requestType: '-',
  timestamp: '-',
  brand: '-',
  formFactor: '-',
  deviceCategory: '-',
};

const convertVDM = (data) => {
  try {
    if (data?.toString().includes('1')) return 'live';
    if (data?.toString().includes('0')) return 'lfv';
  } catch (error) {
    console.error(`convertVDM, ${error}`);
    throw new Error('VDM data is not available!');
  }
};

const vdmsSupplySourceSummary = (data) => ({
  appName: data?.rawData?.app.name,
  channel: data?.rawData?.content?.ext?.channel,
  deviceFamily: data?.rawData?.device?.devicetype,
  vdm: convertVDM(data?.rawData?.content?.livestream),
  timestamp: timestampIntegrators(data?.[CREATED_TIMESTAMP]),
  type: VDMS,
  requestType: REQUEST_TYPE_MAPPING[VDMS],
});

const dssSupplySourceSummary = (data) => ({
  appName: data?.rawData?.app.name,
  channel: data?.rawData?.app?.content?.ext?.channel,
  deviceFamily: data?.rawData?.device?.ext?.devicetype,
  vdm: convertVDM(data?.rawData?.app?.content?.livestream),
  timestamp: timestampIntegrators(data?.[CREATED_TIMESTAMP]),
  type: DSS,
  requestType: REQUEST_TYPE_MAPPING[DSS],
});

const casSupplySourceSummary = (data) => ({
  appName: data?.rawData?.['content-info']?.publisher,
  brand: data?.rawData?.['device-info']?.['distribution-partner'],
  formFactor: data?.rawData?.['device-info']?.['distributor-platform'],
  deviceCategory: data?.rawData?.['device-info']?.category,
  channel: data?.rawData?.['content-info']?.channel,
  deviceFamily: data?.rawData?.['device-info']?.device,
  vdm: data?.rawData?.['content-info']?.vdm,
  timestamp: timestampIntegrators(data?.[CREATED_TIMESTAMP]),
  type: CAS,
  requestType: REQUEST_TYPE_MAPPING[CAS],
});

const supplySourceMap = {
  [VDMS]: vdmsSupplySourceSummary,
  [DSS]: dssSupplySourceSummary,
  [CAS]: casSupplySourceSummary,
};

const resolveSupplySourceSummary = (type, data) => {
  const fn = supplySourceMap[type];
  return fn ? fn(data) : DEFAULT_SUPPLY_SOURCE_SUMMARY;
};

export const useSupplySourceSummaryResolver = (type, data) => {
  return useMemo(() => resolveSupplySourceSummary(type, data), [type, data]);
};
