// ad servers
export const AD_SERVER = {
  HULU: 'online',
  HULU_LABEL: 'Hulu',
  FREEWHEEL: 'freewheel',
  FREEWHEEL_LABEL: 'Freewheel',
  GOOGLE: 'dfp',
  GOOGLE_LABEL: 'Google',
  ROKU: 'roku',
  ROKU_LABEL: 'Roku',
  AMAZON: 'amazon_tam',
  AMAZON_LABEL: 'Amazon TAM',
  TELARIA_FOR_HULU: 'telariapmp',
  TELARIA_FOR_HULU_LABEL: 'Telaria for Hulu',
  TELARIA_FOR_PARTNERS: 'telariaforpartners',
  TELARIA_FOR_PARTNERS_LABEL: 'Telaria for Partners',
};

export const AD_SERVER_OPTIONS = [
  { label: AD_SERVER.HULU_LABEL, value: AD_SERVER.HULU },
  { label: AD_SERVER.GOOGLE_LABEL, value: AD_SERVER.GOOGLE },
  { label: AD_SERVER.FREEWHEEL_LABEL, value: AD_SERVER.FREEWHEEL },
  { label: AD_SERVER.AMAZON_LABEL, value: AD_SERVER.AMAZON },
  { label: AD_SERVER.ROKU_LABEL, value: AD_SERVER.ROKU },
  { label: AD_SERVER.TELARIA_FOR_HULU_LABEL, value: AD_SERVER.TELARIA_FOR_HULU },
  { label: AD_SERVER.TELARIA_FOR_PARTNERS_LABEL, value: AD_SERVER.TELARIA_FOR_PARTNERS },
];

export const AD_SERVER_LABEL_MAP = new Map(AD_SERVER_OPTIONS.map((item) => [item.value, item.label]));

// ingestion statuses
export const INGESTION_STATUS = {
  INGESTING: 'INGESTING',
  SYSTEM_REJECTION: 'SYSTEM_REJECTION',
  ASSET_CREATED: 'ASSET_CREATED',
  PRE_QC_TRANSCODING: 'PRE_QC_TRANSCODING',
  QC: 'QC',
  DVP_REJECTION: 'DVP_REJECTION',
  POST_QC_TRANSCODING: 'POST_QC_TRANSCODING',
  PUBLISHING: 'PUBLISHING',
  PUBLISHED: 'PUBLISHED',
};

export const INGESTION_STATUS_OPTIONS = [
  { label: INGESTION_STATUS.INGESTING, value: INGESTION_STATUS.INGESTING },
  { label: INGESTION_STATUS.SYSTEM_REJECTION, value: INGESTION_STATUS.SYSTEM_REJECTION },
  { label: INGESTION_STATUS.ASSET_CREATED, value: INGESTION_STATUS.ASSET_CREATED },
  { label: INGESTION_STATUS.PRE_QC_TRANSCODING, value: INGESTION_STATUS.PRE_QC_TRANSCODING },
  { label: INGESTION_STATUS.QC, value: INGESTION_STATUS.QC },
  { label: INGESTION_STATUS.DVP_REJECTION, value: INGESTION_STATUS.DVP_REJECTION },
  { label: INGESTION_STATUS.POST_QC_TRANSCODING, value: INGESTION_STATUS.POST_QC_TRANSCODING },
  { label: INGESTION_STATUS.PUBLISHING, value: INGESTION_STATUS.PUBLISHING },
  { label: INGESTION_STATUS.PUBLISHED, value: INGESTION_STATUS.PUBLISHED },
];

// vast types
export const VAST_TYPE = {
  VAST: 'VAST',
  VPAID: 'VPAID',
  MEDIA_FILE: 'MEDIA_FILE',
};

export const VAST_TYPE_OPTIONS = [
  { label: VAST_TYPE.VAST, value: VAST_TYPE.VAST },
  { label: VAST_TYPE.VPAID, value: VAST_TYPE.VPAID },
  { label: VAST_TYPE.MEDIA_FILE, value: VAST_TYPE.MEDIA_FILE },
];

// ingestion types
export const INGESTION_TYPE = {
  PRE_INGEST: 'pre_ingest',
  PRE_INGEST_LABEL: 'Pre Ingest',
  REGULAR_INGEST: 'regular_ingest',
  REGULAR_INGEST_LABEL: 'Regular Ingest',
};

export const INGESTION_TYPE_OPTIONS = [
  { label: INGESTION_TYPE.PRE_INGEST_LABEL, value: INGESTION_TYPE.PRE_INGEST },
  { label: INGESTION_TYPE.REGULAR_INGEST_LABEL, value: INGESTION_TYPE.REGULAR_INGEST },
];

export const formatCpOptions = (rawCp) => {
  return Object.entries(rawCp).map(([id, obj]) => {
    return {
      label: obj.name,
      value: obj.name,
    };
  });
};
