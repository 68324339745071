import { INGESTION_STATUS } from '../../models/creativeTracker/constants';

export const AD_SERVER_KEY = 'adServer';
export const AD_UNIT_ID_KEY = 'adUnitId';
export const AD_SYSTEM_KEY = 'adSystem';
export const MEDIA_FILE_ID_KEY = 'mediafileIdentifier';
export const ADVERTISER_KEY = 'advertiser';
export const CAMPAIGN_MANAGER_KEY = 'campaignManagerAccountName';
export const LAST_SEEN_KEY = 'lastSeen';
export const STATUS_KEY = 'status';

export const INGESTION_STATUS_TO_DESCRIPTION_MAP = new Map([
  [INGESTION_STATUS.INGESTING, 'Vast tag / video file is pulled via api into our ingest pipeline'],
  [INGESTION_STATUS.SYSTEM_REJECTION, 'Vast tag / video file failed Disney system validation'],
  [INGESTION_STATUS.ASSET_CREATED, 'Disney asset is created and bound'],
  [INGESTION_STATUS.PRE_QC_TRANSCODING, 'Sample transcodes are created for the purposes of QC'],
  [INGESTION_STATUS.QC, 'Transcode is evaluated for video quality and mezzanine is checked against our specs'],
  [INGESTION_STATUS.DVP_REJECTION, 'VAST tag / video file failed Disney specs'],
  [INGESTION_STATUS.POST_QC_TRANSCODING, 'Downstream transcodes are created on approved videos'],
  [INGESTION_STATUS.PUBLISHING, 'Transcodes are pushed to our CDN partners'],
  [INGESTION_STATUS.PUBLISHED, 'Transcodes are on our CDN and good to serve'],
]);
